import React from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, { Column, Paging, Lookup } from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import apiUrls from '../../common/api/api-urls';
import LanguageTemplate from '../../components/language-template';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import dataGridDefaultSettings from '../../components/datagrid-settings';
import { Template } from 'devextreme-react/core/template';

const MenuSubPage = ({
    store: {
        languages: { table },
    },
}) => {
    return (
        <div className="sub">
            <DataGrid
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_menu_sub_USERID',
                    url: apiUrls.menu.sub,
                    toolbarCenter: `${useLocation().pathname}`,
                    key: 'subMenuId',
                    language: languages.languageId[table],
                    editing: {
                        allowDeleting: true,
                        allowUpdating:true,
                        allowAdding:true,
                    },
                    editorPreparingArray: ['subMenuId', 'defaultName'],
                })}
            >
                <Column
                    dataField={'subMenuId'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={100}
                    sortOrder={'asc'}
                    allowEditing={false}
                    allowSearch={false}
                />

                <Column
                    dataField={'operationId'}
                    caption={trans('Операция')}
                    dataType={'number'}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.menuOperation,
                            onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'defaultName'}
                    caption={trans('Название по умолчанию')}
                    dataType={'string'}
                />

                <Column
                    dataField={'name'}
                    caption={`${trans('Название подменю',)} (${table.toUpperCase()})`}
                    dataType={'string'}
                />

                <Column
                    dataField={'sortOrder'}
                    caption={trans('Сортировка')}
                    dataType={'number'}
                    width={150}
                    allowSearch={false}
                />

                <Column
                    dataField={'imageUrl'}
                    caption={trans('URL иконки')}
                    dataType={'string'}
                    width={170}
                    allowSearch={false}
                />

                <Column
                    dataField={'isActive'}
                    caption={trans('Активный?')}
                    dataType={'boolean'}
                    width={150}
                    allowSearch={false}
                />

                <Column
                    dataField={'idt'}
                    caption={trans('Дата вставки')}
                    dataType={'datetime'}
                    format={'dd/MM/yy HH:mm:ss'}
                    width={170}
                    allowEditing={false}
                    allowSearch={false}
                />

                <Column
                    dataField={'udt'}
                    caption={trans('Дата обновления')}
                    dataType={'datetime'}
                    format={'dd/MM/yy HH:mm:ss'}
                    width={170}
                    allowEditing={false}
                    allowSearch={false}
                />
                <Column
                    caption={''}
                    width={50}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowReordering={false}
                    allowHiding={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

                <Paging defaultPageSize={10} enabled />
            </DataGrid>
        </div>
    );
};

export default inject('store')(observer(MenuSubPage));
