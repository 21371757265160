import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';

import { trans } from '../../common/utils';
import api from '../../common/api';
import { classes } from '../../styles/material-ui';
import img from '../../static/img/login.jpg';

const ChangePasswordPage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({
        'old-password': '',
        'new-password': '',
        'confirm-password': '',
    });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            return value === values['new-password'];
        });
        return () => {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    }, [values]);

    const submitForm = async () => {
        setIsLoading(true);
        try {
            const response = await api.user.changePassword({
                CurrentPassword: values['old-password'],
                NewPassword: values['new-password'],
                NewPasswordConfirm: values['confirm-password'],
            });
            setIsLoading(false);
            //TODO some logic with response here ?
        } catch (e) {
            setIsLoading(false);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    };

    const useFormInput = (name) => {
        return {
            type: 'password',
            value: values[name],
            onChange: (e) => {
                setValues({ ...values, [e.target.name]: e.target.value });
            },
        };
    };

    return (
        <div className="change-password">
            <Card
                raised
                {...classes.card.standard}
                className="change-password__card"
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={trans('Смена пароля')}
                        height="140"
                        image={img}
                        title={trans('Смена пароля')}
                    />
                    <CardContent>
                        <h5 className="change-password__title">
                            {trans('Смена пароля')}
                        </h5>
                    </CardContent>
                </CardActionArea>
                <ValidatorForm
                    className={`change-password-form`}
                    onSubmit={() => submitForm()}
                    onError={(errors) => console.log(errors)}
                >
                    <TextValidator
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ autoComplete: 'new-password' }}
                        {...useFormInput('old-password')}
                        {...classes.input.standard}
                        label={trans('Текуший пароль')}
                        name="old-password"
                        validators={['required']}
                        errorMessages={[trans('это поле обязательное')]}
                        disabled={isLoading}
                    />
                    <TextValidator
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ autoComplete: 'new-password' }}
                        {...classes.input.standard}
                        {...useFormInput('new-password')}
                        label={trans('Новый пароль')}
                        name="new-password"
                        validators={['required']}
                        errorMessages={[trans('это поле обязательное')]}
                        disabled={isLoading}
                    />

                    <TextValidator
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ autoComplete: 'new-password' }}
                        {...classes.input.standard}
                        {...useFormInput('confirm-password')}
                        label={trans('Подтверждение пароля')}
                        name="confirm-password"
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={[
                            trans('неверное подтверждение пароля'),
                            trans('это поле обязательное'),
                        ]}
                        disabled={isLoading}
                    />
                    <CardActions>
                        <Button
                            className="change-password-form__button"
                            {...classes.button.standard}
                            variant="contained"
                            disabled={isLoading}
                            type="submit"
                            fullWidth={true}
                        >
                            {isLoading ? (
                                <CircularProgress size={24} />
                            ) : (
                                trans('Сменить пароль')
                            )}
                        </Button>
                    </CardActions>
                </ValidatorForm>
            </Card>
        </div>
    );
};

export default ChangePasswordPage;
