import React from 'react';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const MAX_NUM_SNACKBAR = 3;
const HIDE_DURATION = 10000;

const LOCATION = {
    vertical: 'top',
    horizontal: 'right',
};

const NotistackWrapper = ({ children }) => {
    const notistackRef = React.createRef();
    //Possibility to dismiss snackbar with the given key-> key
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            maxSnack={MAX_NUM_SNACKBAR}
            autoHideDuration={HIDE_DURATION}
            anchorOrigin={LOCATION}
            ref={notistackRef}
            variant={'success'}
            action={(key) => (
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClickDismiss(key)}
                >
                    <CloseIcon
                        style={{
                            fontSize: '20px',
                        }}
                    />
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};

export default NotistackWrapper;
