import { types } from 'mobx-state-tree';
import deMessages from 'devextreme/localization/messages/de.json';
import ruMessages from 'devextreme/localization/messages/ru.json';
import trMessages from '../../../tr.json';
import 'devextreme-intl';
import { locale, loadMessages } from 'devextreme/localization';

loadMessages(deMessages);
loadMessages(ruMessages);
loadMessages(trMessages);

const Languages = types
    .model('Languages', {
        active: types.string,
        table: types.string
    })
    .actions(self => {
        return {
            setActiveLanguage(lang, key) {
                //TODO: normal lang here;
                document.cookie = `languageId=${key}; path=/; expires=${new Date(
                    new Date().getTime() + 31536000000
                ).toUTCString()}`;
                locale(lang);
                self.active = lang;
                window.__lng = lang;
            },
            setTableLanguage(lang, key) {
                document.cookie = `languageGridId=${key}; path=/; expires=${new Date(
                    new Date().getTime() + 31536000000
                ).toUTCString()}`;
                self.table = lang;
            },
            setDxGlobalizationLanguage(lang) {

                console.log(lang,'<= setDxGlobalizationLanguage')

            }
        };
    });

export default Languages;
