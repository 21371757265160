import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';

import { languages } from '../../../configs/i18n';
import { inject, observer } from 'mobx-react';

const LanguagePicker = inject('store')(
    observer(({ store }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (lang, key) => {
                store.languages.setActiveLanguage(lang,key);
            },
            handleAnchor = event => setAnchorEl(event);

        return (
            <>
                <IconButton
                    edge="end"
                    aria-label="Language"
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={e => handleAnchor(e.currentTarget)}
                >
                    <ReactCountryFlag
                        code={languages.flags[store.languages.active]}
                        svg
                    />
                </IconButton>
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleAnchor(null)}
                >
                    {Object.entries(languages.flags).map((lang, i) => {
                        return (
                            <MenuItem
                                key={i}
                                onClick={() => {
                                    handleClick(
                                        lang[0],
                                        languages.languageId[lang[0]]
                                    );
                                }}
                            >
                                <ReactCountryFlag code={lang[1]} svg />
                            </MenuItem>
                        );
                    })}
                </Menu>
            </>
        );
    })
);

export default LanguagePicker;
