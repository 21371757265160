import { languages } from '../../configs/i18n';


const trans = (val) => {
    let trans = val;
    if (languages.i18n[window.__lng] !== undefined && languages.i18n[window.__lng][val] !== undefined) {

        trans = languages.i18n[window.__lng][val];
    }
    return trans;
};

export default trans;