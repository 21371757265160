const darkTheme = {
    // palette: {
    //     primary: {
    //         light: '#feea8d',
    //         main: '#ffde35',
    //         dark: '#fdd105',
    //         contrastText: '#000',
    //     },
    //     secondary: {
    //         light: '#313744',
    //         main: '#272d3a',
    //         dark: '#191e2a',
    //         contrastText: '#fff',
    //     },
    // },
    // overrides: {
    //     MuiPickersToolbar: {
    //         toolbar: {
    //             backgroundColor: '#a1a3aa',
    //         },
    //     },
    //     MuiPickersModal: {
    //         dialogAction: {
    //             color: '#a1a3aa',
    //         },
    //     },
    // }
};

const lightTheme = {
    palette: {
        primary: {
            light: '#03b3d0',
            main: '#03a9f4',
            dark: '#038bc8',
            contrastText: '#fff',
        },
        secondary: {
            light: '#313744',
            main: '#272d3a',
            dark: '#191e2a',
            contrastText: '#fff',
        },
    },
    // overrides: {
    //     MuiPickersToolbar: {
    //         toolbar: {
    //             backgroundColor: '#a1a3aa',
    //         },
    //     },
    //     MuiPickersModal: {
    //         dialogAction: {
    //             color: '#a1a3aa',
    //         },
    //     },
    // }
};

const MUIthemes = {
    'web-bma-dark': darkTheme,
    'web-bma-light': lightTheme,
};


export default MUIthemes;
