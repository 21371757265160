import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, {
    Column,
    HeaderFilter,
    Lookup
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import { setUnactiveRows } from '../../common/utils';
import {
    LanguageTemplate,
    dataGridDefaultSettings,
} from '../../components';

const ProviderServicePage = ({
    store: {
        languages: { table },
    },
}) => {
    const ref = useRef();

    return (
        <>
            <DataGrid
                ref={ref}
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_info_providerService_USERID',
                    url: apiUrls.info.providerService,
                    key: 'id',
                    language: languages.languageId[table],
                    languagePicker: false,
                    toolbarCenter: `${useLocation().pathname}`,
                    //toolbarAfter: {
                    //    template: 'todayFilter',
                    //    location: 'after',
                    //},
                    editing: {
                        allowAdding: true,
                        allowUpdating: true,
                        allowDeleting: true,
                    },
                })}
                onRowPrepared={setUnactiveRows}
            >
                <HeaderFilter visible={true} allowSearch={true} />

                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={100}
                    sortOrder={'asc'}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'serviceId'}
                    caption={trans('ID сервиса')}
                    dataType={'number'}
                    width={100}
                    sortOrder={'asc'}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'serviceProviderId'}
                    caption={trans('Сервис провайдер')}
                    dataType={'number'}
                    width={120}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={true}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.serviceProvider,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'mainServiceId'}
                    caption={trans('Основной сервис')}
                    dataType={'number'}
                    width={250}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={true}
                    allowHiding={true}
                    showInColumnChooser={true}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.service,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                        allowClearing={true}
                    />
                </Column>/>

                <Column
                    dataField={'name'}
                    caption={trans('Название')}
                    dataType={'string'}
                    allowFixing={false}
                    width={350}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'price'}
                    caption={trans('Прайс')}
                    dataType={'number'}
                    width={120}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                    allowHiding={true}
                    showInColumnChooser={true}
                />

                <Column
                    dataField={'stockCode'}
                    caption={trans('Код товара')}
                    dataType={'string'}
                    allowFixing={false}
                    width={150}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'percentage'}
                    caption={trans('Процент провайдера')}
                    dataType={'number'}
                    width={120}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                    allowHiding={true}
                    showInColumnChooser={true}
                />

                <Column
                    dataField={'activationUrl'}
                    caption={trans('URL активации')}
                    dataType={'string'}
                    allowFixing={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'emailTemplate'}
                    caption={trans('Название Email шаблона')}
                    dataType={'string'}
                    allowFixing={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'idt'}
                    caption={trans('Дата вставки')}
                    dataType={'datetime'}
                    format={'dd/MM/yyyy HH:mm:ss'}
                    width={150}
                    visible={false}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    visible={false}
                    allowHiding={true}
                    showInColumnChooser={true}
                />

                <Column
                    caption={''}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

            </DataGrid>
        </>

    );
};

export default inject('store')(observer(ProviderServicePage));
