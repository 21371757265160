import React from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, { Column, MasterDetail, HeaderFilter, Lookup, ColumnFixing } from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import { trans } from '../../common/utils';
import apiUrls from '../../common/api/api-urls';
import { languages } from '../../configs/i18n';
import dataGridDefaultSettings from '../../components/datagrid-settings';


const TransactionServiceProviderPage = ({
    store: {
        languages: { table },
    },
}) => {
    const colorChange = (e) => {
        if (e.rowType == "data") {
            e.rowElement.bgColor = e.data.color;
        }
    }

    return (
        <DataGrid
            {...dataGridDefaultSettings({
                url: apiUrls.transaction.serviceProvider,
                language: languages.languageId[table],
                key: 'id',
                toolbar: true,
                languagePicker: false,
                toolbarCenter: `${useLocation().pathname}`,
                settingStorageName: 'BMA_grid_transaction_serviceProvider_USERID',
                editing: {
                    allowAdding: false,
                    allowUpdating: false,
                },
                columnFixing: { enabled: true },
            })}
            onRowPrepared={colorChange}
        >
            <HeaderFilter visible={true} allowSearch={true} />

            <Column caption={trans('Транзакция')} fixed={true}>

                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                    sortOrder={'desc'}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'serviceProviderId'}
                    caption={trans('Сервис провайдер')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    width={100}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.serviceProvider,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>
                <Column
                    dataField={'serviceId'}
                    caption={trans('Сервис')}
                    dataType={'number'}
                    allowHeaderFiltering={true}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.service,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>
            </Column>
            <Column caption={trans('Клиент')}>
                <Column
                    dataField={'account'}
                    caption={trans('Аккаунт')}
                    dataType={'string'}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'payerId'}
                    caption={trans('Клиент')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'payerAmount'}
                    caption={trans('Оплата')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    width={100}
                />
            </Column>
            <Column caption={'Pay2Play'}>
                <Column
                    dataField={'serviceProviderAmount'}
                    caption={trans('Исходящая оплата')}
                    dataType={'number'}
                    width={100}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'incomeAmount'}
                    caption={trans('Сумма дохода')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    allowFixing={true}
                    visible={true}
                    width={100}
                />
                <Column
                    dataField={'currencyId'}
                    caption={trans('Валюта')}
                    dataType={'number'}
                    allowHeaderFiltering={true}
                    width={70}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.currency,
                            onBeforeSend: (method, ajaxOptions) => {
                                //ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>
                <Column
                    dataField={'currencyRate'}
                    caption={trans('Курс валюты')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    width={70}
                />
                <Column
                    dataField={'paymentIdt'}
                    caption={trans('Дата платежа')}
                    dataType={'datetime'}
                    format={'dd.MM.yyyy HH:mm:ss'}
                    allowHeaderFiltering={false}
                />
            </Column>
            <Column caption={'Статус'}>
                <Column
                    dataField={'stateId'}
                    caption={trans('ID статуса')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    width={60}
                />
                <Column
                    dataField={'subStateID'}
                    caption={trans('ID подстатуса')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    width={60}
                />
                <Column
                    dataField={'errorCode'}
                    caption={trans('Код ошибки')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    width={60}
                />
                <Column
                    dataField={'isFinal'}
                    caption={trans('Финальный?')}
                    dataType={'boolean'}
                    allowHeaderFiltering={false}
                    width={50}
                />
                <Column
                    dataField={'paymentStatusIdt'}
                    caption={trans('Дата статуса')}
                    dataType={'datetime'}
                    format={'dd.MM.yyyy HH:mm:ss'}
                    allowHeaderFiltering={false}
                />
            </Column>
            <Column caption={'Сервис провайдер'}>

                <Column
                    dataField={'pcTransactionID'}
                    caption={trans('PC Transaction ID')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    allowFixing={true}
                    visible={true}
                />
                <Column
                    dataField={'pinCode'}
                    caption={trans('PIN код')}
                    dataType={'string'}
                    allowHeaderFiltering={false}
                    allowFixing={true}
                    visible={true}
                />
                <Column
                    dataField={'resultCode'}
                    caption={trans('Код результата')}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    allowFixing={true}
                    visible={true}
                />
                <Column
                    dataField={'color'}
                    caption={trans('Color')}
                    dataType={'string'}
                    allowHeaderFiltering={true}
                    allowHiding={true}
                    visible={false}
                />
            </Column>
            <Column
                caption={''}
                width={50}
                visible={true}
                allowFixing={false}
                allowEditing={false}
                allowSearch={false}
                allowHeaderFiltering={false}
                allowHiding={false}
                allowReordering={false}
            />


        </DataGrid >
    );
};

export default inject('store')(observer(TransactionServiceProviderPage));
