import React, { useState, useEffect, useRef } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Drawer,
    Modal,
    Backdrop,
    Zoom,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import MenuIcon from '@material-ui/icons/Menu';
import { LoadPanel } from 'devextreme-react/load-panel';

import api from '../../common/api';
import LLTopMenu from './menu';
import LanguagePicker from './language-picker';
import ThemePicker from './theme-picker';
import { languages } from '../../configs/i18n';
import { trans } from '../../common/utils';
import Loginform from '../../pages/user/loginform';
import ScrollPicker from './scroll-picker';
// import MatchDetailView from '../../pages/prematch/components/matchDetailView';

import './header.scss';

export const LLHeader = inject('store')(
    observer(({ store, history }) => {
        const [drawer, setDrawer] = useState(false);
        const [topMenuItems, setTopMenuItems] = useState([]);
        const [isShowSettings, setIsShowSettings] = useState(false);

        const ref = useRef();

        window.__appStore = store; //for use later in datagrid-settings.

        const saveAction = (e) => {
            if (e.ctrlKey && e.which === 83) {
                // Check for the Ctrl key being pressed, and if the key = [S] (83)
                const buttons = document.getElementsByClassName(
                    'dx-datagrid-save-button'
                );
                Object.keys(buttons).map((index) => buttons[index].click());
                e.preventDefault();
            }
        };

        useEffect(() => {
            document.addEventListener('keydown', saveAction, false);
            return () => {
                document.removeEventListener('keydown', saveAction, false);
            };
        }, []);

        useEffect(() => {
            getMenuItems().then((items) => {
                setTopMenuItems(items);
            });
            localStorage.setItem('prevURL', history.location.pathname);
            localStorage.setItem('currentURL', history.location.pathname);
        }, [store.languages.active]);

        useEffect(() => {
            const currentURL = localStorage.getItem('currentURL');
            if (currentURL !== history.location.pathname) {
                localStorage.setItem('prevURL', currentURL);
                localStorage.setItem('currentURL', history.location.pathname);
            }
        }, [history.location.pathname]);

        useEffect(() => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                setIsShowSettings(false);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        }, [ref]);

        const getMenuItems = async () => {
            try {
                let responseArray = [];
                let response = await api.menu.list({
                    languageid: languages.languageId[store.languages.active],
                });
                if (!response.error) {
                    responseArray = response.items;
                } else {
                    throw new Error('Error loading top menu');
                }
                return responseArray;
            } catch (error) {
                console.error(error.message);
            }
        };

        const logout = async () => await api.user.logout();

        const logoutProcess = () => {
            logout().then(() => {
                store.user.logout();
            });
        };

        return (
            <div
                className={`header header_${
                    store.user.isLogged() ? 'visible' : 'hidden'
                }`}>
                <Drawer
                    open={drawer}
                    onClose={() => {
                        setDrawer(false);
                    }}>
                    <LLTopMenu
                        menuItems={topMenuItems}
                        closeDrawer={() => {
                            setDrawer(false);
                        }}
                        activeMenu={store.user.menuDefaultActive}
                    />
                </Drawer>
                <AppBar position="static">
                    <Toolbar
                        classes={{
                            root: `ll-header-toolbar${
                                store.theme.active === 'material.teal.dark'
                                    ? '_big'
                                    : '_small'
                            }`,
                        }}>
                        {/*<div className="header_top-menu">*/}
                        {/*    <DevMenu dataSource={topMenuItems}*/}
                        {/*        displayExpr={'name'}*/}
                        {/*        showFirstSubmenuMode={{*/}
                        {/*            name: 'onHover',*/}
                        {/*            delay: { show: 0, hide: 300 }*/}
                        {/*        }}*/}
                        {/*        orientation={'horizontal'}*/}
                        {/*        submenuDirection={'auto'}*/}
                        {/*        hideSubmenuOnMouseLeave={true}*/}
                        {/*        onItemClick={e => {*/}
                        {/*                if (e.itemData.link) { history.push(e.itemData.link) }*/}
                        {/*            }*/}
                        {/*        }*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <div className="header_top-menu_left">
                            <span
                                onClick={() => {
                                    setDrawer(true);
                                }}
                                style={{ cursor: 'pointer' }}>
                                <MenuIcon />
                            </span>
                        </div>

                        <div className="header_top-menu_center">{trans('Pay2Play админ панель')}</div>

                        <div className="header_top-menu_right">
                            <div
                                className={`header_buttons-container${
                                    isShowSettings ? '' : '_hidden'
                                }`}
                                ref={ref}>
                                <ScrollPicker />
                                <ThemePicker />
                                <LanguagePicker />
                                <Button
                                    className="header__button"
                                    title={trans('Exit')}
                                    variant="contained"
                                    color="primary"
                                    onClick={logoutProcess}>
                                    <ExitToAppIcon />
                                </Button>
                            </div>
                            <span
                                onClick={() => {
                                    setIsShowSettings(!isShowSettings);
                                }}
                                // style={{ cursor: 'pointer' }}
                                className="header_right-burger-button">
                                <MenuIcon />
                            </span>
                        </div>
                    </Toolbar>
                </AppBar>

                <Modal
                    className="ll-modal login-modal"
                    open={store.user.needRelogin}
                    //onClose={() => setIsModal(false)}
                    closeAfterTransition
                    disableEnforceFocus={true}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Zoom in={store.user.needRelogin}>
                        <div className="ll-modal__content">
                            <Loginform />
                        </div>
                    </Zoom>
                </Modal>
                <LoadPanel
                    visible={store.loader.active}
                    delay={200}
                    //showIndicator={this.state.showIndicator}
                />
            </div>
        );
    })
);

export default withRouter(observer(LLHeader));
