import Base from '../base';
import apiUrls from '../api-urls';
export default class StateStoringAPI extends Base {
    async save(key, state) {
        return this.apiClient.post(apiUrls.stateStoring.save + '?key=' + key, state);
    }
    
    async load(key) {
        return this.apiClient.get(apiUrls.stateStoring.load + '?key=' + key, {});
    }
}
