import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MUIthemes } from "./styles/material-ui";
import LLRouter from "./common/router";

import api from "./common/api";
import { languages } from "./configs/i18n";
import { NotistackWrapper } from "./components";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.dark.css";
import "./app.scss";

const App = ({ store }) => {
    const theme = createMuiTheme({
        ...MUIthemes[`web-bma-${store.theme.themeName()}`],
    });
    const [loading, setLoading] = useState(true);

    const check = async () => {
        const response = await api.user.check();
        if (response && response.value) {
            store.user.login({ userId: response.value });
        }
    };

    useEffect(() => {
        check()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        store.languages.setActiveLanguage(
            store.languages.active,
            languages.languageId[store.languages.active]
        );
        store.languages.setDxGlobalizationLanguage(languages.languageId[store.languages.active]);
    }, []);

    return (
        <div
            className="App"
            id="bma-website"
            data-lang={store.languages.active}>
            {!loading && (
                <NotistackWrapper>
                    <MuiThemeProvider theme={theme}>
                        <LLRouter />
                    </MuiThemeProvider>
                </NotistackWrapper>
            )}
        </div>
    );
};

export default inject("store")(App);
