export const az = {

    "/info/income-percent": "Gəlir faizləri",
    "/info/payer": "Müştərilər",
    "/info/service": "Xidmətlər",
    "/info/provider-service": "Provayder xidmətləri",
    "/info/pay2play-pincode": "Pay2Play xidmət provayderi - Pin kodlar",

    "/logs/email-messages": "Loq - Email göndərişləri",
    "/logs/verify-query": "Loq - Akkaunt yoxlama sorğuları",
    "/logs/main": "Loq",
    "/logs/currency": "Loq - Valyuta",
    "/logs/income-percent": "Loq - Gəlir faizləri",
    "/logs/payer": "Loq - Müştərilər",
    "/logs/service": "Loq - Xidmətlər",
    "/logs/service-provider-data-exchange": "Loq - Xidmət provayderlərilə məlumat mübadiləsi",
    "/logs/transaction-service-provider-status": "Loq - Xidmət provayderlərilə tranzaksiya statusları",
    "/logs/pin-code": "Loq - Pay2Play PIN kodlar",


    "/menu/main": "Menyu siyahısı",
    "/menu/tree": "Menyu ağacı",
    "/menu/operation": "Menyu əməliyyatları",
    "/menu/sub": "Alt menyular",

    "/settings/currency": "Valyuta siyahısı",
    "/settings/email-message-template": "Email göndərişləri şablonları",

    "/transaction/payment-provider": "Giriş ödənişlər",
    "/transaction/service-provider": "Çıxış ödənişlər",



    "Pay2Play админ панель": "Pay2Play admin panel",
    "Строк: " : "Yazı: ",
    "Дата вставки": "Tarix (əlavə etmə)",
    "Дата обновления": "Tarix (yenilənmə)",
    "ID": "ID",
    "Название": "Ad",
    "Сервис": "Xidmət",
    "Аккаунт": "Akkaunt",
    "Баланс": "Balans",
    "Шаблон": "Şablon",
    "Курс": "Məzənnə",
    "Короткое название": "Qısa ad",
    "Код": "Kod",
    "Сумма от": "Məbləğdən",
    "Сумма до": "Məbləğədək",
    "Процент": "Faiz",
    "Сервис провайдер": "Xidmət provayderi",
    "Настройки успешно сброшены": "Sazlamalar müvəffəqiyyətlə silindi",
    "Произошла ошибка": "Səhv baş verdi",
    "Сброс настроек": "Sazlamaların silinməsi",
    "Вы уверенны что хотите сбросить все настройки?": "Bütün sazlamaların silinməsinə əminsiz?",
    "Да": "Bəli",
    "Неверное имя пользователя, пароль или код": "İstifadəçi adı, şifrə və ya kod səhvdir",
    "Отсканируйте этот QR код с помощью Google Authenticator": "Bu QR kodu Google Authenticator ilə skan edin",
    "Неверный код от Google Authenticator": "Google Authenticator kodu səhvdir",
    "Вход": "Giriş",
    "Войти": "Daxil ol",
    "Имя пользователя": "İstifadəçi adı",
    "Пароль": "Şifrə",
    "Сменить пароль": "Şifrəni dəyiş",
    "Текуший пароль": "Cari şifrə",
    "это поле обязательное": "bu sahə mütləqdir",
    "Новый пароль": "Yeni şifrə",
    "Подтверждение пароля": "Şifrənin təkrarı",
    "неверное подтверждение пароля": "şifrənin təkrarı səhvdir",
    "Смена пароля": "Şifrə dəyişməsi",
    "Провайдер оплат": "Ödəmə provayderi",
    "ID платежа": "Ödəmə ID",
    "Сумма": "Məbləğ",
    "Терминал": "Terminal",
    "Транзакция": "Tranzaksiya",
    "Клиент": "Müştəri",
    "Оплата": "Ödəniş",
    "Исходящая оплата": "Çıxış ödəniş",
    "Сумма дохода": "Gəlir məbləği",
    "Валюта": "Valyuta",
    "Курс валюты": "Valyuta məzənnəsi",
    "Дата платежа": "Ödəmə tarixi",
    "ID статуса": "Status ID",
    "ID подстатуса": "Alt status ID",
    "Код ошибки": "Səhv kodu",
    "Финальный?": "Final?",
    "Дата статуса": "Status tarixi",
    "PIN код": "PIN kod",
    "Код результата": "Nəticə kodu",
    "Сервис провайдер": "Service provider",
    "Провайдер для оплаты": "Ödəmə üçün provayder",
    "Родитель": "Valideyn",
    "Макс сумма (терминал)": "Maks məbləğ (terminal)",
    "Мин сумма (терминал)": "Min məbləğ (terminal)",
    "Макс сумма (онлайн)": "Maks məbləğ (online)",
    "Мин  сумма (онлайн)": "Min məbləğ (online)",
    "Сортировка": "Çeşidləmə",
    "Цена фиксированная?": "Sabit qiymət?",
    "На сайте?": "Saytda?",
    "Regex аккаунта": "Regex akkaunt",
    "Текст аккаунта": "Akkaunt mətn",
    "ID сервиса": "Xidmət ID",
    "Основной сервис": "Əsas xidmət",
    "Прайс": "Qiymət",
    "Код товара": "Məhsul kodu",
    "Процент провайдера": "Provayderin faizi",
    "Использован?": "İstifadə edilib?",
    "URL активации": "URL aktivasuya",
    "Название Email шаблона" : "Email şablon adı"
};
