import { types } from 'mobx-state-tree';

const UserInfo = types.model('UserInfo', {
    userId: types.integer
});

const User = types
    .model('User', {
        info: types.maybeNull(UserInfo),
        needRelogin: false,
        menuDefaultActive: types.maybeNull(types.string)
    })
    .actions(self => {
        return {
            login(userData) {
                self.needRelogin = false;
                self.info = UserInfo.create(userData);
            },
            setNeedRelogin(flag) {
                self.needRelogin = flag;
            },
            logout() {
                self.info = null;
            },
            setMenuDefaultActive(menu) {
                self.menuDefaultActive = menu;
            }
        };
    })
    .views(self => {
        return {
            isLogged() {
                return !!(self.info && self.info.userId);
            }
        };
    });

export default User;
