import { types, applySnapshot } from 'mobx-state-tree';
import {
    User,
    Languages,
    Theme,
    ActivePageForSelect,
    Scroll,
    Loader,
} from './models';
import { languages } from './../configs/i18n';

import { locale } from 'devextreme/localization';

const RootStore = types.model('RootStore', {
    user: types.optional(User, {
        info: null,
        needRelogin: false,
    }),
    languages: types.optional(Languages, {
        active:
            Object.keys(languages.languageId).find(
                (key) =>
                    languages.languageId[key] ==
                    document.cookie.match(/(?<=languageId=)\d/),


            ) || 'az',
        table:
            Object.keys(languages.languageId).find(
                (key) =>
                    languages.languageId[key] ==
                    document.cookie.match(/(?<=languageGridId=)\d/),
            ) || 'az',
    }),
    activePageForSelect: types.optional(ActivePageForSelect, {
        pageId: '',
    }),
    theme: types.optional(Theme, {
        active: 'material.teal.dark',
    }),
    scroll: types.optional(Scroll, {
        type:
            localStorage.getItem('scrollType') === 'virtual'
                ? 'virtual'
                : 'infinite',
    }),
    loader: types.optional(Loader, {
        active: false,
    }),
});

window.__lng = 'az'; //to use in trans until better solution is found

let store = null;
const initStore = (snapshot = null) => {
    if (store === null) store = RootStore.create();
    if (snapshot) applySnapshot(store, snapshot);
    return store;
};

export default initStore;
