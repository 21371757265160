import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import initStore from "./store";
import { Provider } from "mobx-react";

const store = initStore();
window.__store = store;

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
