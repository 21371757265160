import { types } from "mobx-state-tree";

const Loader = types
    .model("Loader", {
        active: types.boolean,
    })
    .actions((self) => {
        return {
            setLoader(active) {
                self.active = active;
            },
        };
    });

export default Loader;
