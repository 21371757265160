import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import LogCurrencyPage from "./currency";
import LogIncomePercentPage from "./income-percent";
import LogPayerPage from "./payer";
import LogServicePage from "./service";
import LogTransactionServiceProviderStatusPage from "./transaction-service-provider-status";
import LogServiceProviderDataExchangePage from "./service-provider-data-exchange";
import LogPinCodePage from "./pinCode";

import './logs.scss';


const renderCurrentPage = (id) => {
    switch (id) {
        case 'currency':
            return <LogCurrencyPage />;
        case 'incomePercent':
            return <LogIncomePercentPage />;
        case 'payer':
            return <LogPayerPage />;
        case 'service':
            return <LogServicePage />;
        case 'serviceProviderDataExchange':
            return <LogServiceProviderDataExchangePage />;
        case 'transactionServiceProviderStatus':
            return <LogTransactionServiceProviderStatusPage />;
        case 'pinCode':
            return <LogPinCodePage />;
        default:
            return <LogCurrencyPage />;
    }
};

const LogsPage = ({ store: { activePageForSelect } }) => {

    useEffect(() => {
        activePageForSelect.setActivePage('currency');
    }, []);

    return renderCurrentPage(activePageForSelect.pageId);
};

export default inject("store")(observer(LogsPage));