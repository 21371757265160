import React from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { trans } from '../../common/utils';
import api from '../../common/api';
import { classes } from '../../styles/material-ui';
import img from '../../static/img/login.jpg';

import './user.scss';

const resetSettings = async () => await api.user.resetSettings();

const ResetUsersGridSettingsPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const snackBarOptions = {
        infoOptions: trans('Настройки успешно сброшены'),
        errorOptions: [trans('Произошла ошибка'), { variant: 'error' }],
    };

    return (
        <div className="reset-users-grid-settings">
            <Card
                raised
                {...classes.card.standard}
                className="reset-users-grid-settings__card"
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={trans('Сброс настроек')}
                        height="140"
                        image={img}
                        title={trans('Сброс настроек')}
                    />
                    <CardContent>
                        <h5>
                            {trans('Вы уверенны что хотите сбросить все настройки?',)}
                        </h5>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button
                        {...classes.button.accent}
                        className="reset-users-grid-settings__button"
                        onClick={() => {
                            resetSettings().then((res) => {
                                try {
                                    if (res.value === 'reset') {
                                        enqueueSnackbar(
                                            snackBarOptions.infoOptions,
                                        );
                                    } else {
                                        enqueueSnackbar(
                                            ...snackBarOptions.errorOptions,
                                        );
                                    }
                                } catch (e) {
                                    enqueueSnackbar(
                                        ...snackBarOptions.errorOptions,
                                    );
                                }
                            });
                        }}
                    >
                        {trans('Да')}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default ResetUsersGridSettingsPage;
