import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, {
    Column,
    HeaderFilter,
    FormItem,
    Lookup
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';


import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import {
    LanguageTemplate,
    dataGridDefaultSettings,
} from '../../components';
import './custom.css';

const EmailMessageTemplatePage = ({
    store: {
        languages: { table },
    },
}) => {
    const ref = useRef();
    return (
        <>
            <DataGrid
                ref={ref}
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_settings_emt_USERID',
                    url: apiUrls.settings.emailMessageTemplate,
                    key: 'key',
                    language: languages.languageId[table],
                    languagePicker: false,
                    toolbarCenter: `${useLocation().pathname}`,
                    editing: {
                        allowAdding: true,
                        allowUpdating: true,
                        allowDeleting: false,
                        mode: "form",
                    },
                    editorPreparingArray: ["key"]
                })}
            >
                <HeaderFilter visible={true} allowSearch={true} />

                <Column
                    dataField={'key'}
                    caption={trans('Название')}
                    dataType={'number'}
                    width={200}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                >
                    <FormItem
                        colSpan={2}
                        visible={true}
                        isRequired={true}
                        editorType="dxTextArea"
                    />
                </Column >

                <Column
                    dataField={'value'}
                    caption={trans('Шаблон')}
                    dataType={'string'}
                    allowSearch={false}
                    width={1200}
                    allowHeaderFiltering={false}
                >
                    <FormItem
                        colSpan={2}
                        isRequired={false}
                        editorType="dxTextArea"
                        editorOptions={{ height: 200 }}
                        label={{ location: "left", text: `${trans("Шаблон")}:` }}

                    />
                </Column >

                <Column
                    caption={''}
                    width={50}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                >
                    <FormItem
                        colSpan={2}
                        visible={false}
                        isRequired={false}
                        editorType="dxTextArea"
                    />
                </Column >



                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

            </DataGrid>
        </>

    );
};

export default inject('store')(observer(EmailMessageTemplatePage));
