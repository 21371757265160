import React from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import { trans } from '../../common/utils';
import apiUrls from '../../common/api/api-urls';
import { languages } from '../../configs/i18n';
import dataGridDefaultSettings from '../../components/datagrid-settings';
import PageSelector from '../../components/page-selector';

const LogServiceProviderDataExchangePage = ({
    store: {
        languages: { table },
    },
    setCurrentPage,
}) => {
    return (
        <DataGrid
            {...dataGridDefaultSettings({
                url: apiUrls.logs.serviceProviderDataExchange,
                key: 'id',
                language: languages.languageId[table],
                toolbar: true,
                toolbarCenter: { template: 'pageName', location: 'center' },
                settingStorageName: 'BMA_grid_logs_spde_USERID',
                languagePicker: false,
                editing: {
                    allowAdding: false,
                    allowUpdating: false,
                },
                columnFixing: { enabled: false },
            })}>
            <Column
                dataField={'id'}
                caption={trans('ID')}
                dataType={'number'}
                sortOrder={'desc'}
                showInColumnChooser={false}
                allowHiding={false}
            />
            <Column
                dataField={'serviceProviderId'}
                caption={trans('Сервис провайдер')}
                dataType={'number'}
                allowHeaderFiltering={false}
                width={100}
            >
                <Lookup
                    dataSource={createStore({
                        key: 'key',
                        loadUrl: apiUrls.lookup.serviceProvider,
                        onBeforeSend: (method, ajaxOptions) => {
                            //ajaxOptions.data.languageid = 1;
                            ajaxOptions.xhrFields = {
                                withCredentials: true,
                            };
                        },
                    })}
                    displayExpr={'value'}
                    valueExpr={'key'}
                />
            </Column>
            <Column
                dataField={'request'}
                caption={trans('Request')}
                dataType={'string'}
                width={400}
            />

            <Column
                dataField={'response'}
                caption={trans('Response')}
                dataType={'string'}
                width={400}
            />

            <Column
                dataField={'exception'}
                caption={trans('Exception')}
                dataType={'string'}
            />

            <Column
                dataField={'ip'}
                caption={trans('IP')}
                dataType={'string'}
            />

            <Column
                dataField={'idt'}
                caption={trans('Insert Date Time')}
                dataType={'datetime'}
                format={'dd.MM.yyyy HH:mm:ss'}
            />

            <Column
                caption={''}
                width={20}
                visible={true}
                allowFixing={false}
                allowEditing={false}
                allowSearch={false}
                allowHeaderFiltering={false}
                allowHiding={false}
                allowReordering={false}
            />
            <Template
                name={'pageName'}
                render={() => (
                    <PageSelector
                        setCurrentPage={(comp) => setCurrentPage(comp)}
                    />
                )}
            />
        </DataGrid>
    );
};

export default inject('store')(observer(LogServiceProviderDataExchangePage));
