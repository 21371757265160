import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { trans } from '../../../common/utils';

import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const MenuItem = (props) => {
    const {
        item,
        closeDrawer,
        activeMenu,
        parents = [],
        activeMenuParents,
        setActiveMenuParents,
    } = props;

    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
        if (activeMenu === item.link) {
            setActiveMenuParents(parents);
        }
    }, [activeMenu]);

    useEffect(() => {
        if (activeMenuParents && activeMenuParents.includes(item.name)) {
            setExpanded(true);
        }
    }, [activeMenuParents]);

    const renderMenuItem = (item, parents = []) => {
        if (item.items) {
            return (
                <Accordion
                    classes={{
                        root: 'll-expansion-panel ll-expansion-panel_standard',
                        expanded: 'll-expansion-panel_expanded_standard',
                    }}
                    expanded={parents.includes(item.name) || expanded}
                    onChange={(e, isExpanded) => {
                        setExpanded(isExpanded);
                    }}
                    key={item.Id}
                    className="menu__item-panel">
                    <AccordionSummary
                        expandIcon={<ExpandLessIcon />}
                        className="menu__item-summary">
                        <span className="menu__item-name">{trans(item.name)}</span>
                    </AccordionSummary>
                    <AccordionDetails className="menu__item-details">
                        {item.items.map((subMenu, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    item={subMenu}
                                    parents={[...parents, item.name]}
                                    activeMenu={activeMenu}
                                    closeDrawer={closeDrawer}
                                    activeMenuParents={activeMenuParents}
                                    setActiveMenuParents={setActiveMenuParents}
                                />
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
            );
        } else
            return (
                <NavLink key={item.Id} to={item.link} onClick={closeDrawer}>
                    {trans(item.name)}
                </NavLink>
            );
    };

    return <>{renderMenuItem(item)}</>;
};

const LLTopMenu = (props) => {
    const { menuItems, closeDrawer, activeMenu } = props;

    let [activeMenuParents, setActiveMenuParents] = useState(null);

    return (
        <div className={'header__drawer-menu'}>
            {menuItems &&
                menuItems.map((item, i) => (
                    <MenuItem
                        key={i}
                        item={item}
                        closeDrawer={closeDrawer}
                        activeMenu={activeMenu}
                        activeMenuParents={activeMenuParents}
                        setActiveMenuParents={setActiveMenuParents}
                    />
                ))}
        </div>
    );
};

export default LLTopMenu;
