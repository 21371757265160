import Base from '../base';
import apiUrls from '../api-urls';
export default class CustomerAPI extends Base {
    async getCustomerInfo(params) {
        return this.apiClient.get(apiUrls.customers.customerList, {}, params);
    }
    async getCustomerGroups(params) {
        return this.apiClient.get(apiUrls.customers.customerGroup, {}, params);
    }
    async emailAction(data) {
        return this.apiClient.post(apiUrls.customers.insertEmail, data, true);
    }
    async saveCustomerInfo(data) {
        return this.apiClient.put(apiUrls.customers.customerList, data, true);
    }
    async getBlockCustomer(params) {
        return this.apiClient.get(apiUrls.customers.blockCustomer, {}, params);
    }
    async setBlockCustomer(data) {
        return this.apiClient.post(apiUrls.customers.blockCustomer, data, true);
    }
    async getCustomerAuthenticator(params) {
        return this.apiClient.get(
            apiUrls.customers.customerAuthenticator,
            {},
            params,
        );
    }
    async setCustomerAuthenticator(data) {
        return this.apiClient.post(
            apiUrls.customers.customerAuthenticator,
            data,
            true,
        );
    }
    async getCustomerPaymentSystemsInfo(params) {
        return this.apiClient.get(
            apiUrls.customers.paymentSystemsInfo,
            {},
            params,
        );
    }
    async setCustomerPaymentSystemFPUnblock(data) {
        return this.apiClient.post(
            apiUrls.customers.paymentSystemsFPUnblock,
            data,
            true,
        );
    }
    async customerUpdateLimits(data) {
        return this.apiClient.post(apiUrls.customers.customerUpdateLimits, data, true);
    }
    async customerUpdateCommission(data) {
        return this.apiClient.post(apiUrls.customers.customerUpdateCommission, data, true);
    }
    async customerCreditToBonus(data) {
        return this.apiClient.post(apiUrls.customers.customerCreditToBonus, data, true);
    }
    async customerCreateCommission(data) {
        return this.apiClient.post(apiUrls.customers.customerCreateCommission, data, true);
    }
    async customerDeleteCommission(data) {
        return this.apiClient.post(apiUrls.customers.customerDeleteCommission, data, true);
    }
    async getCustomerCommission(params) {
        return this.apiClient.get(apiUrls.customers.customerCommission, {}, params);
    }
}
