import { types } from 'mobx-state-tree';

const Scroll = types
    .model('Scroll', {
        type: types.string,
    })
    .actions(self => {
        return {
            set(scrollType) {
                self.type = scrollType;
            },
        };
    })

export default Scroll;