import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    useLocation
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { inject, observer } from "mobx-react";
import { LLHeader } from "../../layout";
import MenuSetter from "../../layout/header/menu/menu-setter";
import { trans } from "../utils";
import {
    LoginPage,
    HomePage,
    ResetUsersGridSettingsPage,
    ChangePasswordPage,

    IncomePercentPage,
    PayerPage,
    ServicePage,
    ProviderServicePage,
    Pay2PlayPinCodePage,

    LogsPage,
    LogCurrencyPage,
    LogEmailMessagePage,
    LogIncomePercentPage,
    LogPayerPage,
    LogServicePage,
    LogServiceProviderDataExchangePage,
    LogTransactionServiceProviderStatusPage,
    LogVerifyQueryPage,
    LogPinCodePage,

    MenuEditPage,
    MenuTreePage,
    MenuOperationPage,
    MenuSubPage,

    EmailMessageTemplatePage,
    CurrencyPage,

    TransactionPaymentProviderPage,
    TransactionServiceProviderPage,

    FileManagerPage
} from "../../pages";



const LLRouter = inject("store")(
    observer(({ store }) => {
        const PageSetter = ({ activeMenu, Page }) => (
            <MenuSetter activeMenu={activeMenu}>
                <Helmet>
                    <title>{`Pay2Play - ${trans(useLocation().pathname)}`}</title>
                </Helmet>
                <Page />
            </MenuSetter>
        );

        return (
            <Router>
                {store.user.isLogged() && <LLHeader />}
                <div
                    className="site-content"
                    data-lang={store.languages.active}
                    data-scroll={store.scroll.type}
                >
                    {store.user.isLogged() ? (
                        <Switch>
                            <Route
                                path="/"
                                exact
                                component={() => (
                                    <PageSetter
                                        activeMenu="null"
                                        Page={HomePage}
                                    />
                                )}
                            />


                            <Route
                                path="/account/reset-settings"
                                component={() => (
                                    <PageSetter
                                        activeMenu="Account"
                                        Page={ResetUsersGridSettingsPage}
                                    />
                                )}
                            />
                            <Route
                                path="/account/change-password"
                                component={() => (
                                    <PageSetter
                                        activeMenu="Account"
                                        Page={ChangePasswordPage}
                                    />
                                )}
                            />



                            <Route
                                path="/info/income-percent"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={IncomePercentPage}
                                    />
                                )}
                            />
                            <Route
                                path="/info/payer"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={PayerPage}
                                    />
                                )}
                            />
                            <Route
                                path="/info/service"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={ServicePage}
                                    />
                                )}
                            />
                            <Route
                                path="/info/provider-service"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={ProviderServicePage}
                                    />
                                )}
                            />
                            <Route
                                path="/info/pay2play-pincode"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={Pay2PlayPinCodePage}
                                    />
                                )}
                            />




                            <Route
                                path="/logs/main"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogsPage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/currency"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogCurrencyPage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/email-messages"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogEmailMessagePage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/income-percent"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogIncomePercentPage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/payer"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogPayerPage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/service"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogServicePage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/service-provider-data-exchange"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogServiceProviderDataExchangePage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/transaction-service-provider-status"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogTransactionServiceProviderStatusPage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/pin-code"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogPinCodePage}
                                    />
                                )}
                            />
                            <Route
                                path="/logs/verify-query"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={LogVerifyQueryPage}
                                    />
                                )}
                            />



                            <Route
                                path="/menu/main"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={MenuEditPage}
                                    />
                                )}
                            />
                            <Route
                                path="/menu/tree"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={MenuTreePage}
                                    />
                                )}
                            />
                            <Route
                                path="/menu/operation"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={MenuOperationPage}
                                    />
                                )}
                            />
                            <Route
                                path="/menu/sub"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={MenuSubPage}
                                    />
                                )}
                            />





                            <Route
                                path="/settings/currency"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={CurrencyPage}
                                    />
                                )}
                            />
                            <Route
                                path="/settings/email-message-template"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={EmailMessageTemplatePage}
                                    />
                                )}
                            />



                            <Route
                                path="/transaction/payment-provider"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={TransactionPaymentProviderPage}
                                    />
                                )}
                            />
                            <Route
                                path="/transaction/service-provider"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={TransactionServiceProviderPage}
                                    />
                                )}
                            />


                            <Route
                                path="/file-manager/cdn"
                                component={() => (
                                    <PageSetter
                                        activeMenu={`${trans(useLocation().pathname)}`}
                                        Page={FileManagerPage}
                                    />
                                )}
                            />



                        </Switch>
                    ) : (
                            <Switch>
                                <Route
                                    exact
                                    path="/user/login"
                                    component={() => (
                                        <PageSetter
                                            activeMenu={null}
                                            Page={LoginPage}
                                        />
                                    )}
                                />
                                <Route
                                    path="*"
                                    render={() => <Redirect to="/user/login" />}
                                />
                            </Switch>
                        )}
                </div>
            </Router>
        );
    }),
);

export default LLRouter;
