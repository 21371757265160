import { ru } from './translations/_ru';
import { az } from './translations/_az';

export const languages = {
    i18n: {
        'az': az,
        'ru': ru,
    },
    languageId: {
        'az': 2,
        'ru': 3,
    },
    list: {
        az: 'Azerice',
        ru: 'Русский',
    },
    flags: {
        az: 'az',
        ru: 'ru',
    }
};

