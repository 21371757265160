import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import ReactCountryFlag from 'react-country-flag';
import { inject, observer } from 'mobx-react';

import { languages } from '../../configs/i18n';

const LanguageTemplate = inject('store')(
    observer(({ store }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (lang, key) => {
            store.languages.setTableLanguage(lang, key);
        };
        return (
            <div className="template-custom-header-component">
                <IconButton
                    edge="end"
                    aria-label="Language"
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <ReactCountryFlag
                        code={languages.flags[store.languages.table]}
                        svg
                    />
                </IconButton>
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClick={() => setAnchorEl(null)}>
                    {Object.entries(languages.flags).map((lang, i) => {
                        return (
                            <MenuItem
                                key={i}
                                onClick={() =>
                                    handleClick(
                                        lang[0],
                                        languages.languageId[lang[0]]
                                    )
                                }>
                                <ReactCountryFlag code={lang[1]} svg />
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    })
);

export default LanguageTemplate;
