import React, { useEffect } from 'react';
import { inject,observer } from 'mobx-react';

const MenuSetter = props => {
    const { children, activeMenu, setMenuDefaultActive } = props;
    
    useEffect(() => {
        setMenuDefaultActive(activeMenu);
    }, [activeMenu]);

    return <>{children}</>;
};

export default inject(stores => ({
    setMenuDefaultActive: stores.store.user.setMenuDefaultActive,
}))(observer(MenuSetter));
