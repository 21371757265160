import React from 'react';
import {inject, observer} from "mobx-react";
import { withRouter } from 'react-router-dom';
import LoginForm from './loginform';
import videoFile from '../../static/video/bg-video.mp4';

import './login.scss';

const LoginPage = inject('store')(
    observer(({ store, ...props }) => {

    return (
        <div className="login">
            <video autoPlay muted loop id="login-video">
                <source src={videoFile} type="video/mp4" />
            </video>

                <div className="content content--canvas">
                    <canvas
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    <LoginForm />
                </div>


        </div>
    )
}));


export default withRouter(LoginPage);
