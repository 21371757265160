import Base from '../base';
import apiUrls from '../api-urls';

export default class CouponApi extends Base {
    async couponAction(data) {
        return this.apiClient.post(apiUrls.backOffice.couponHandle, data);
    }
    async singleСouponAction(data) {
        return this.apiClient.post(apiUrls.backOffice.singleCouponHandle, data);
    }
    async cancelMtsTicket(data) {
        return this.apiClient.post(apiUrls.backOffice.cancelMtsTicket, data, true);
    }
    async deleteTicket(data) {
        return this.apiClient.post(apiUrls.backOffice.deleteTicket, data, true);
    }
}