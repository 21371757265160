import React, { useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';

import FileManager, { Permissions, ItemView } from 'devextreme-react/file-manager';
import RemoteFileSystemProvider from 'devextreme/file_management/remote_provider';
import apiUrls from '../../common/api/api-urls';
import { Popup } from 'devextreme-react/popup';


const remoteProvider = new RemoteFileSystemProvider({
    endpointUrl: apiUrls.fileManager.cdn
});

const FileManagerPage = () => {

    const [previewVisible, setPreviewVisibility] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const fileManagerRef = useRef();

    const onPreviewClick = () => {
        if (fileManagerRef.current.instance.getSelectedItems()) {
            setImgSrc("https://cdn.pay2play.az/" + fileManagerRef.current.instance.getSelectedItems()[0].path);
            setPreviewVisibility(true);
        }
    };

    //const previewButtonOptions = {
    //    text: 'Preview',
    //    onClick: onPreviewClick
    //};

    const popupOnHiding = () => {
        setPreviewVisibility(false);
    };


    return (
        <React.Fragment>
            <FileManager fileSystemProvider={remoteProvider}
                height={function () {
                    return window.innerHeight / 1.1;
                }}
                width={"100%"}
                ref={fileManagerRef}

                onSelectedFileOpened={onPreviewClick }
            >
                {/*<Toolbar>*/}
                {/*    <Item name="showNavPane" visible="true" />*/}
                {/*    <Item name="separator" />*/}
                {/*    <Item name="create" />*/}
                {/*    <Item name="refresh" />*/}
                {/*    <Item name="separator" location="after" />*/}
                {/*    <Item name="switchView" />*/}

                {/*    <FileSelectionItem widget="dxButton" location="before" options={previewButtonOptions} />*/}
                {/*    <FileSelectionItem name="separator" />*/}
                {/*    <FileSelectionItem name="rename" />*/}
                {/*    <FileSelectionItem name="separator" />*/}
                {/*    <FileSelectionItem name="delete" />*/}
                {/*    <FileSelectionItem name="separator" />*/}
                {/*    <FileSelectionItem name="refresh" />*/}
                {/*    <FileSelectionItem name="clearSelection" />*/}
                {/*</Toolbar>*/}
                <ItemView mode={"thumbnails"} showParentFolder={false} showFolders={false} />
                <Permissions
                    // create={true}
                    copy={true}
                    move={true}
                    delete={true}
                    rename={true}
                    upload={true}
                    download={true}>
                </Permissions>
            </FileManager>

            <Popup
                visible={previewVisible}
                onHiding={popupOnHiding}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={false}
                position={"left top"}
                width={"auto"}
                height={"auto"}
            >
                <img src={imgSrc} />
            </Popup>
        </React.Fragment>
    );
}


export default inject('store')(observer(FileManagerPage));
