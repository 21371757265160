import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch } from '@material-ui/core';

import { trans } from '../../../common/utils';

const ThemePicker = inject('store')(
    observer(({ store }) => {
        const [isDarkTheme, setIsDarkTheme] = useState(true);

        useEffect(() => {
            const userTheme = localStorage.getItem('userTheme');
            if (userTheme) {
                store.theme.set(userTheme);
                setIsDarkTheme(store.theme.themeName() !== 'dark');
            } else {
                setIsDarkTheme(store.theme.themeName() !== 'dark');
            }
            appendCssThemeFiles();
        }, [store.theme.active]);

        const addStyle = (url, id) => {
            const existingEl = document.querySelector(`#dynamicTheme_${id}`);
            if (existingEl) {
                existingEl.remove();
            }
            const style = document.createElement('link');
            style.href = url;
            style.rel = 'stylesheet';
            style.async = true;
            style.id = `dynamicTheme_${id}`;
            document.head.appendChild(style);
        };

        const appendCssThemeFiles = () => {
            addStyle(
                `${process.env.PUBLIC_URL}/css/themes/dx/dx.${store.theme.active}.css`,
                1
            );
            addStyle(
                `${process.env.PUBLIC_URL}/css/themes/custom/${store.theme.active}.css`,
                2
            );
        };

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    {trans('Theme')}:
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <p>{trans('Dark')}</p>
                    <Switch
                        checked={isDarkTheme}
                        onChange={() => {
                            if (store.theme.themeName() === 'dark') {
                                store.theme.set('material.blue.light.compact');
                                localStorage.setItem(
                                    'userTheme',
                                    'material.blue.light.compact'
                                );
                            } else {
                                store.theme.set('material.teal.dark');
                                localStorage.setItem(
                                    'userTheme',
                                    'material.teal.dark.compact'
                                );
                            }
                        }}
                        color="default"
                    />
                    <p>{trans('Light')}</p>
                </div>
            </>
        );
    })
);

export default ThemePicker;
