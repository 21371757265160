import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';

import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from '../../common/api';
import img from './../../static/img/login.jpg';
import { classes } from '../../styles/material-ui';
import { trans } from '../../common/utils';

import './login.scss';

const LoginForm = inject('store')(
    observer(({ store, ...props }) => {
        const [loading, setLoading] = useState(false);
        const [googleAuth, setGoogleAuth] = useState(false);
        const [googleCode, setGoogleCode] = useState(false);
        const [googleImage, setGoogleImage] = useState('');
        const username = useFormInput(''),
            password = useFormInput(''),
            code = useFormInput('');
        const { enqueueSnackbar } = useSnackbar();

        const submitForm = async () => {
            setLoading(true);
            try {
                const response = await api.user.login({
                    username: username.value,
                    password: password.value,
                    code: code.value,
                });
                setLoading(false);
                if (!response.userId) {
                    if (response.code) {
                        setGoogleImage(
                            'https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=' +
                            encodeURIComponent(response.code),
                        );
                        setGoogleAuth(true);
                    } else {
                        enqueueSnackbar(
                            trans('Неверное имя пользователя, пароль или код'),
                            { variant: 'error' },
                        );
                    }
                } else {
                    if (!store.user.isLogged()) {
                        props.history.push('/');
                    }
                    store.user.login({ userId: response.userId });
                }
            } catch (e) {
                setLoading(false);
                enqueueSnackbar(e.message, { variant: 'error' });
            }
        };

        const renderGoogleAuthEnabler = () => {
            return (
                <>
                    <p>
                        {trans('Отсканируйте этот QR код с помощью Google Authenticator',)}
                    </p>
                    <p>
                        <img
                            src={googleImage}
                            alt=""
                            title=""
                            style={{ width: '250px' }}
                        />
                    </p>
                    {renderGoogleAuthCodeField()}
                </>
            );
        };

        const renderGoogleAuthCodeField = () => {
            return (
                <>
                    <p>{trans('Неверный код от Google Authenticator')}</p>
                    <TextValidator
                        InputLabelProps={{ shrink: true }}
                        {...code}
                        {...classes.input.standard}
                        label="Code"
                        name="code"
                        disabled={loading}
                    />
                </>
            );
        };

        return (
            <Card
                raised
                {...classes.card.standard}
                className={`login-card ${googleAuth ? 'login-card_bigger' : ''
                    }`}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        alt={trans('Вход')}
                        height="140"
                        image={img}
                        title={trans('Вход')}
                    />
                    <CardContent>
                        {!googleAuth ? (
                            <h5 className="login-title">{trans('Войти')}</h5>
                        ) : (
                                <h5 className="login-title">
                                    {trans('Enable Google Authenticator')}
                                </h5>
                            )}
                    </CardContent>
                </CardActionArea>
                <ValidatorForm
                    className="login-form"
                    onSubmit={() => submitForm()}
                    onError={(errors) => console.log(errors)}
                >
                    <div
                        className={`login__auth-block ${!googleAuth ? 'enabled' : 'disabled'
                            }`}
                    >
                        <TextValidator
                            InputLabelProps={{ shrink: true }}
                            {...username}
                            {...classes.input.standard}
                            label={trans('Имя пользователя')}
                            name="username"
                            disabled={loading || googleCode}
                            validators={['required']}
                            errorMessages={['this field is required']}
                        />
                        <TextValidator
                            InputLabelProps={{ shrink: true }}
                            {...password}
                            {...classes.input.standard}
                            label={trans('Пароль')}
                            name="password"
                            type="password"
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={loading || googleCode}
                        />
                        {renderGoogleAuthCodeField()}
                    </div>
                    <div
                        className={`login__google-block ${googleAuth ? 'enabled' : 'disabled'
                            }`}
                    >
                        {googleAuth && renderGoogleAuthEnabler()}
                    </div>
                    <CardActions>
                        <Button
                            className="login-form__button"
                            {...classes.button.standard}
                            variant="contained"
                            disabled={loading}
                            type="submit"
                        >
                            {loading ? (
                                <CircularProgress size={24} />
                            ) : (
                                    trans('Войти')
                                )}
                        </Button>
                    </CardActions>
                </ValidatorForm>
            </Card>
        );
    }),
);

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        setValue(e.target.value);
    }

    return {
        type: 'text',
        value,
        onChange: handleChange,
    };
};

export default withRouter(LoginForm);
