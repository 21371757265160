import React from 'react';
import { withRouter } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { classes } from "../../styles/material-ui";
import { Select, ListSubheader, MenuItem, FormControl } from '@material-ui/core';

import { trans } from '../../common/utils';

import './page-selector.scss';


const pageNames = {
    logs: {
        Logs: {
            currency: trans('/logs/currency'),
            incomePercent: trans('/logs/income-percent'),
            payer: trans('/logs/payer'),
            service: trans('/logs/service'),
            serviceProviderDataExchange: trans('/logs/service-provider-data-exchange'),
            transactionServiceProviderStatus: trans('/logs/transaction-service-provider-status'),
            pinCode: trans('/logs/pin-code'),
        },
    }
};

const PageSelector = ({ store: { activePageForSelect, theme }, history }) => {

    const handleChange = (event) => {
        activePageForSelect.setActivePage(event.target.value);
    };

    let arrayForSelect = [];

    const renderSelect = () => {
        if (history.location.pathname.includes('uof')) {
            return Object.keys(pageNames.uofLogs).map(item => <MenuItem key={item} value={item}>{trans(pageNames.uofLogs[item])}</MenuItem>)
        } else if (history.location.pathname.includes('logs')) {
            return Object.keys(pageNames.logs).map((groupName, i) => {
                arrayForSelect = [];
                arrayForSelect.push(<ListSubheader key={i} >{trans(groupName)}</ListSubheader>);
                Object.keys(pageNames.logs[groupName]).map(item => arrayForSelect.push(<MenuItem key={item} value={item}>{trans(pageNames.logs[groupName][item])}</MenuItem>));
                return arrayForSelect;
            })
        } else {
            return Object.keys(pageNames.multilangs).map(item => <MenuItem key={item} value={item}>{trans(pageNames.multilangs[item])}</MenuItem>)
        }
    };

    return (<FormControl style={{ width: '300px' }}>
        <Select
            value={activePageForSelect.pageId}
            onChange={handleChange}
            {...classes.select.standard}
            className={`page-selector page-selector_${theme.themeName()}`}
        >
            {renderSelect()}
        </Select>
    </FormControl>
    );
};

export default inject('store')(withRouter(observer(PageSelector)));