import ApiClient from './api-client';
import {
    CouponApi,
    UserAPI,
    MenuAPI,
    StateStoringAPI,
    CustomerAPI,
    CurrencyAPI,
    NewAdminAPI,
    GroupsSettingsApi,
} from './methods/';

import { API_PREFIX } from './api-url-config';

const api = new ApiClient({ prefix: API_PREFIX });

const apiSingleton = {
    apiClient: api,
    coupon: new CouponApi({ apiClient: api }),
    user: new UserAPI({ apiClient: api }),
    menu: new MenuAPI({ apiClient: api }),
    stateStoring: new StateStoringAPI({ apiClient: api}),
    currency: new CurrencyAPI({ apiClient: api}),
    newAdmin:new NewAdminAPI({ apiClient: api}),
    customers: new CustomerAPI({ apiClient: api}),
    groupsSettings: new GroupsSettingsApi({ apiClient: api}),
};

export default apiSingleton;
