import React from 'react';
import { Switch } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

import { trans } from '../../../common/utils';

const ScrollPicker = inject('store')(
    observer(({ store }) => {
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    {trans('Mode')}:
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '20px',
                    }}
                >
                    <p>{trans('View')}</p>
                    <Switch
                        checked={store.scroll.type === 'virtual' ? true : false}
                        onChange={(event) => {
                            if (event.target.checked) {
                                store.scroll.set('virtual');
                                localStorage.setItem('scrollType', 'virtual');
                            } else {
                                store.scroll.set('infinite');
                                localStorage.setItem('scrollType', 'infinite');
                            }
                        }}
                        color="default"
                    />
                    <p>{trans('Edit')}</p>
                </div>
            </>
        );
    })
);

export default ScrollPicker;
