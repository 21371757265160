import Base from '../base';
import apiUrls from '../api-urls';

export default class MenuAPI extends Base {
    async list(params) {
        return this.apiClient.get(apiUrls.menu.list, {}, params);
    }
    async edit() {
        return this.apiClient.get(apiUrls.menu.edit, {});
    }
    async tree() {
        return this.apiClient.get(apiUrls.menu.tree, {});
    }
    async operation() {
        return this.apiClient.get(apiUrls.menu.operation, {});
    }
    async sub() {
        return this.apiClient.get(apiUrls.menu.sub, {});
    }
}
