import { types } from 'mobx-state-tree';

const Theme = types
    .model('Theme', {
        active: types.string,
    })
    .actions(self => {
        return {
            set(themeName) {
                self.active = themeName;
            },
        };
    })
    .views(self => {
        return {
            themeName() {
                let theme = self.active.split('.');
                return theme[2];
            }
        }
    });

export default Theme;
