import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, {
    Column,
    HeaderFilter,
    Lookup,
    Editing,
    Form,
    Popup
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';

import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import { setUnactiveRows } from '../../common/utils';
import {
    LanguageTemplate,
    dataGridDefaultSettings,
} from '../../components';

const ServicePage = ({
    store: {
        languages: { table },
    },
}) => {
    const ref = useRef();
    const propGrid = {
        ...dataGridDefaultSettings({
            settingStorageName: 'BMA_grid_info_pay2play_pincode_USERID',
            url: apiUrls.info.pay2playPinCode,
            key: 'id',
            language: languages.languageId[table],
            languagePicker: false,
            toolbarCenter: `${useLocation().pathname}`,
            toolbarAfter: {
                location: 'after'
                //template: () => { return (<Button text='Import' width='100' />) },
            },
        })
    }

    delete propGrid.editing

    return (
        <>
            <DataGrid
                ref={ref}
                {...propGrid}
            >
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                    <Popup title="PIN Code Editor" showTitle={true} width={700} height={525} />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="serviceId" />
                            <Item dataField="isUsed" dataType='boolean' />
                        </Item>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="pinCode" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 100 }} />
                        </Item >
                    </Form>
                </Editing>

                <HeaderFilter visible={true} allowSearch={true} />

                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={100}
                    sortOrder={'asc'}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'serviceId'}
                    caption={trans('ServiceID')}
                    dataType={'number'}
                    width={100}
                    sortOrder={'asc'}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />


                <Column
                    dataField={'pinCode'}
                    caption={trans('PIN код')}
                    dataType={'string'}
                    allowFixing={false}
                    width={400}
                    allowEditing={true}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'isUsed'}
                    caption={trans('Использован?')}
                    dataType={'boolean'}
                    width={100}
                    allowSearch={false}
                />

                <Column
                    dataField={'idt'}
                    caption={trans('Дата вставки')}
                    dataType={'datetime'}
                    format={'dd/MM/yyyy HH:mm:ss'}
                    width={150}
                    visible={false}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    visible={false}
                    allowHiding={true}
                    showInColumnChooser={true}
                />
                <Column
                    caption={''}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

            </DataGrid>
        </>

    );
};

export default inject('store')(observer(ServicePage));
