import React from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, { Column, HeaderFilter, Lookup } from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import { trans } from '../../common/utils';
import apiUrls from '../../common/api/api-urls';
import { languages } from '../../configs/i18n';
import dataGridDefaultSettings from '../../components/datagrid-settings';

const TransactionPaymentProviderPage = ({
    store: {
        languages: { table },
    },
}) => {

    return (
        <DataGrid
            {...dataGridDefaultSettings({
                url: apiUrls.transaction.paymentProvider,
                language: languages.languageId[table],
                key: 'id',
                toolbar: true,
                languagePicker: false,
                toolbarCenter: `${useLocation().pathname}`,
                settingStorageName: 'BMA_grid_transaction_paymentProvider_USERID',
                editing: {
                    allowAdding: false,
                    allowUpdating: false,
                },
                columnFixing: { enabled: false },
            })}
        >
            <HeaderFilter visible={true} allowSearch={true} />

            <Column
                dataField={'id'}
                caption={trans('ID')}
                sortOrder={'desc'}
                dataType={'number'}
                width={100}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'paymentProviderID'}
                caption={trans('Провайдер оплат')}
                dataType={'number'}
                width={140}
                allowHeaderFiltering={false}
            >
                <Lookup
                    dataSource={createStore({
                        key: 'key',
                        loadUrl: apiUrls.lookup.paymentProvider,
                        onBeforeSend: (method, ajaxOptions) => {
                            ajaxOptions.xhrFields = {
                                withCredentials: true,
                            };
                        },
                    })}
                    displayExpr={'value'}
                    valueExpr={'key'}
                />
            </Column>
            <Column
                dataField={'serviceId'}
                caption={trans('Сервис')}
                dataType={'number'}
                width={250}
                allowHeaderFiltering={true}
            >
                <Lookup
                    dataSource={createStore({
                        key: 'key',
                        loadUrl: apiUrls.lookup.service,
                        onBeforeSend: (method, ajaxOptions) => {
                            ajaxOptions.xhrFields = {
                                withCredentials: true,
                            };
                        },
                    })}
                    displayExpr={'value'}
                    valueExpr={'key'}
                />
            </Column>
            <Column
                dataField={'paymentId'}
                caption={trans('ID платежа')}
                dataType={'number'}
                width={100}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'account'}
                caption={trans('Аккаунт')}
                dataType={'string'}
                width={200}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'amount'}
                caption={trans('Сумма')}
                dataType={'number'}
                width={100}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'terminal'}
                caption={trans('Терминал')}
                dataType={'number'}
                width={100}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'rrn'}
                caption={trans('RRN')}
                dataType={'number'}
                width={140}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'intRef'}
                caption={trans('IntRef')}
                dataType={'string'}
                width={140}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'idt'}
                caption={trans('Дата вставки')}
                dataType={'datetime'}
                format={'dd/MM/yyyy HH:mm:ss'}
                width={180}
                visible={true}
                allowFixing={false}
                allowEditing={false}
                allowSearch={false}
                allowHeaderFiltering={false}
            />
            <Column
                dataField={'customerId'}
                caption={trans('Клиент')}
                dataType={'number'}
                width={250}
                allowHeaderFiltering={true}
            >
                <Lookup
                    dataSource={createStore({
                        key: 'key',
                        loadUrl: apiUrls.lookup.customer,
                        onBeforeSend: (method, ajaxOptions) => {
                            ajaxOptions.xhrFields = {
                                withCredentials: true,
                            };
                        },
                    })}
                    displayExpr={'value'}
                    valueExpr={'key'}
                />
            </Column>
            <Column
                caption={''}
                width={20}
                visible={true}
                allowFixing={false}
                allowEditing={false}
                allowSearch={false}
                allowHeaderFiltering={false}
                allowHiding={false}
                allowReordering={false}
            />
        </DataGrid >
    );
};

export default inject('store')(observer(TransactionPaymentProviderPage));
