import React from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, { Column, Paging, Lookup } from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import dataGridDefaultSettings from '../../components/datagrid-settings';
import { Template } from 'devextreme-react/core/template';
import LanguageTemplate from '../../components/language-template';

const MenuTreePage = ({
    store: {
        languages: { table },
    },
}) => {
    return (
        <div className="tree">
            <DataGrid
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_menu_tree_USERID',
                    url: apiUrls.menu.tree,
                    toolbarCenter: `${useLocation().pathname}`,
                    key: 'id',
                    language: languages.languageId[table],
                    editing: {
                        allowDeleting: true,
                        allowUpdating: true,
                        allowAdding: true,
                    },
                    editorPreparingArray: ['id'],
                })}
            >
                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={100}
                    sortOrder={'asc'}
                    allowEditing={false}
                    allowSearch={false}
                />

                <Column
                    dataField={'menuId'}
                    caption={trans('Меню')}
                    dataType={'number'}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.menuMain,
                            onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'subMenuId'}
                    caption={trans('Подменю')}
                    dataType={'number'}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.menuSubMenu,
                            onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'subMenuParentId'}
                    caption={trans('Родительский подменю')}
                    dataType={'number'}
                >
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.menuSubMenu,
                            onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.data.languageid = 1;
                                ajaxOptions.xhrFields = {
                                    withCredentials: true,
                                };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                    />
                </Column>

                <Column
                    dataField={'idt'}
                    caption={trans('Дата вставки')}
                    dataType={'datetime'}
                    format={'dd/MM/yy HH:mm:ss'}
                    width={170}
                    allowEditing={false}
                    allowSearch={false}
                />

                <Column
                    dataField={'udt'}
                    caption={trans('Дата обновления')}
                    dataType={'datetime'}
                    format={'dd/MM/yy HH:mm:ss'}
                    width={170}
                    allowEditing={false}
                    allowSearch={false}
                />
                <Column
                    caption={''}
                    width={50}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowReordering={false}
                    allowHiding={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

                <Paging defaultPageSize={10} enabled />
            </DataGrid>
        </div>
    );
};

export default inject('store')(observer(MenuTreePage));
