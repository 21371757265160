import React from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import { trans } from '../../common/utils';
import apiUrls from '../../common/api/api-urls';
import { languages } from '../../configs/i18n';
import { LanguageTemplate, dataGridDefaultSettings } from '../../components';
import PageSelector from '../../components/page-selector';

const LogPaymentStatusPage = ({
    store: {
        languages: { table },
    },
    setCurrentPage,
}) => {
    return (
        <DataGrid
            {...dataGridDefaultSettings({
                url: apiUrls.logs.transactionServiceProviderStatus,
                key: 'logId',
                language: languages.languageId[table],
                toolbar: true,
                languagePicker: false,
                toolbarCenter: { template: 'pageName', location: 'center' },
                settingStorageName: 'BMA_grid_logs_tsps_USERID',
                columnFixing: { enabled: false },
                editing: {
                    allowAdding: false,
                    allowUpdating: false,
                },
            })}>
            <Column caption={trans('Поля лога')}>
                <Column
                    dataField={'logId'}
                    caption={trans('LogID')}
                    dataType={'number'}
                    sortOrder={'asc'}
                    showInColumnChooser={false}
                    allowHiding={false}
                    width={100}
                />
                <Column
                    dataField={'logIdt'}
                    caption={trans('LogIdt')}
                    dataType={'datetime'}
                    format={'dd.MM.yyyy HH:mm:ss'}
                    width={180}
                />
                <Column
                    dataField={'logActionId'}
                    caption={trans('LogAction')}
                    dataType={'number'}>
                    width={120}
                    <Lookup
                        dataSource={[
                            {
                                type: 1,
                                value: `1 - ${trans('Insert')}`,
                            },
                            { type: 2, value: `2 - ${trans('Update')}` },
                            { type: 3, value: `3 - ${trans('Delete')}` },
                        ]}
                        displayExpr={'value'}
                        valueExpr={'type'}
                    />
                </Column>
                <Column
                    dataField={'logUserId'}
                    caption={trans('LogUser')}
                    dataType={'number'}>
                    width={120}
                    <Lookup
                        dataSource={createStore({
                            key: 'key',
                            loadUrl: apiUrls.lookup.user,
                            onBeforeSend: (method, ajaxOptions) => {
                                ajaxOptions.data.languageid =
                                    languages.languageId[table];
                                ajaxOptions.xhrFields = { withCredentials: true };
                            },
                        })}
                        displayExpr={'value'}
                        valueExpr={'key'}
                        allowClearing={true}
                    />
                </Column>
                <Column
                    dataField={'logSqlUserName'}
                    caption={trans('Log Sql User Name')}
                    dataType={'string'}
                    width={120}
                />
                <Column
                    dataField={'logSessionId'}
                    caption={trans('Log Session ID')}
                    dataType={'number'}
                    width={100}
                />
            </Column>

            <Column caption={trans('Поля объекта')}>
                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                />
                <Column
                    dataField={'stateId'}
                    caption={trans('State ID')}
                    dataType={'number'}
                />
                <Column
                    dataField={'subStateId'}
                    caption={trans('Sub State ID')}
                    dataType={'string'}
                />
                <Column
                    dataField={'errorCode'}
                    caption={trans('Error Code')}
                    dataType={'number'}
                />
                <Column
                    dataField={'isFinal'}
                    caption={trans('Is Final')}
                    dataType={'boolean'}
                />
                <Column
                    dataField={'pcTransactionId'}
                    caption={trans('PC Transaction ID')}
                    dataType={'number'}
                />
            </Column>

            <Column
                caption={''}
                width={20}
                visible={true}
                allowFixing={false}
                allowEditing={false}
                allowSearch={false}
                allowHeaderFiltering={false}
                allowReordering={false}
                allowHiding={false}
            />

            <Template
                name={'languageBar'}
                render={() => <LanguageTemplate />}
            />
            <Template
                name={'pageName'}
                render={() => (
                    <PageSelector
                        setCurrentPage={(comp) => setCurrentPage(comp)}
                    />
                )}
            />
        </DataGrid>
    );
};

export default inject('store')(observer(LogPaymentStatusPage));
