import fetch from 'isomorphic-fetch';
import queryString from 'querystring';
import {isDevMode} from '../utils';

export default class ApiClient {
    constructor({prefix = 'api/v1'} = {}) {
        this.prefix = prefix;
    }
    
    get(requestUrl, payload = {}, params = {}) {
        return this.request({
            url: requestUrl,
            method: 'get',
            body: payload,
            params
        });
    }

    put(requestUrl, payload = {}, formData=false) {
        return this.request({
            url: requestUrl,
            method: 'put',
            body: payload,
            formData: formData
        });
    }

    patch(requestUrl, payload = {}) {
        return this.request({
            url: requestUrl,
            method: 'put',
            body: payload
        });
    }

    post(requestUrl, payload = {}, formData=false) {
        return this.request({
            url: requestUrl,
            method: 'post',
            body: payload,
            formData: formData
        });
    }

    delete(requestUrl) {
        return this.request({
            url: requestUrl,
            method: 'delete'
        });
    }


    request({url, method, params = {}, body, formData}) {
        let requestUrl = url;
        const query = queryString.stringify(params);
        if (query) {
            requestUrl += `?${ query }`
        }

        const headers = {};
        headers['Accept'] = 'application/json';
        if (!formData) {
            headers['Content-Type'] = 'application/json';
        }

        const init = {
            method,
            headers: headers,
            credentials: (isDevMode()) ? 'include' : 'same-origin',
        };


        if (method !== 'get' && method !== 'head') {
            if (formData) {
                let form = new FormData();
                Object.keys(body).map(key => {
                    form.append(key, JSON.stringify(body[key]))
                });
                init.body = form;
            } else {
                init.body = JSON.stringify(body);
            }
        }

        return fetch(requestUrl, init).then(res => {
            if (res.status >= 400) {
                if (res.status === 401) {
                    console.error('Unauthorized access');
                    window.__appStore.user.setNeedRelogin(true);
                    return false;
                } else {
                    throw new Error('Bad response from server');
                }
            }
            return res.text()
        }).then(text =>{
            try {
                const data = JSON.parse(text);
                return data
            } catch(err) {
               return true
            }
        });
    }
}