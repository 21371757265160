export const ru = {

    "/info/income-percent": "Процент с оплат",
    "/info/payer": "Клиенты",
    "/info/service": "Сервисы",
    "/info/provider-service": "Сервисы провайдеров",
    "/info/pay2play-pincode": "Сервис провайдер Pay2Play - Pin коды",

    "/logs/main": "Логи",
    "/logs/currency": "Лог - Валюта",
    "/logs/email-messages": "Лог - Email рассылка",
    "/logs/income-percent": "Лог - Процент с дохода",
    "/logs/payer": "Лог - Клиенты",
    "/logs/service": "Лог - Сервисы",
    "/logs/service-provider-data-exchange": "Лог - Обмен данными с сервис провайдерами",
    "/logs/transaction-service-provider-status": "Лог - Статус транзакции с сервис провайдерами",
    "/logs/verify-query": "Лог - Запросы на проверку аккаунта",
    "/logs/pin-code": "Лог - Pay2Play PIN коды",


    "/menu/main": "Список меню",
    "/menu/tree": "Дерево меню",
    "/menu/operation": "Операции меню",
    "/menu/sub": "Подменю",

    "/settings/currency": "Список валют",
    "/settings/email-message-template": "Шаблоны для Email рассылки",

    "/transaction/payment-provider": "Платежи входящие",
    "/transaction/service-provider": "Платежи исходящие",

};
