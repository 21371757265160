import Base from '../base';
import apiUrls from '../api-urls';

export default class UserAPI extends Base {
    async login(data) {
        return this.apiClient.post(apiUrls.user.login, data);
    }

    async logout() {
        return this.apiClient.post(apiUrls.user.logout, {});
    }

    async resetSettings() {
        return this.apiClient.delete(apiUrls.user.resetSettings, {});
    }

    async changePassword(data) {
        return this.apiClient.post(apiUrls.user.changePassword, data);
    }

    async check() {
        return this.apiClient.get(apiUrls.user.check);
    }

}
