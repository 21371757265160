import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, {
    Column,
    HeaderFilter,
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';

import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import { setUnactiveRows } from '../../common/utils';
import {
    LanguageTemplate,
    dataGridDefaultSettings,
} from '../../components';

const CurrencyPage = ({
    store: {
        languages: { table },
    },
}) => {
    const ref = useRef();

    return (
        <>
            <DataGrid
                ref={ref}
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_settings_currency_USERID',
                    url: apiUrls.settings.currency,
                    key: 'currencyId',
                    language: languages.languageId[table],
                    languagePicker: false,
                    toolbarCenter: `${useLocation().pathname}`,
                    //toolbarAfter: {
                    //    template: 'todayFilter',
                    //    location: 'after',
                    //},
                    editing: {
                        allowAdding: false,
                        allowUpdating: true,
                        allowDeleting: false,
                    },
                })}
                onRowPrepared={setUnactiveRows}
            >
                <HeaderFilter visible={true} allowSearch={true} />

                <Column
                    dataField={'currencyId'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={148}
                    sortOrder={'asc'}
                    allowFixing={false}
                    allowHiding={false}
                    allowHeaderFiltering={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowEditing={false}
                />

                <Column
                    dataField={'name'}
                    caption={trans('Название')}
                    dataType={'string'}
                    width={263}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                />

                <Column
                    dataField={'shortName'}
                    caption={trans('Короткое название')}
                    dataType={'string'}
                    width={150}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    allowHiding={true}
                    showInColumnChooser={true}
                    visible={false}
                />

                <Column
                    dataField={'code'}
                    caption={trans('Код')}
                    dataType={'string'}
                    width={274}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                />

                <Column
                    dataField={'rate'}
                    caption={trans('Курс')}
                    dataType={'number'}
                    allowFixing={false}
                    width={269}
                    allowHeaderFiltering={false}
                />

                <Column
                    caption={''}
                    width={50}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

            </DataGrid>
        </>

    );
};

export default inject('store')(observer(CurrencyPage));
