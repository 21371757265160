import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import DataGrid, {
    Column,
    HeaderFilter,
    Lookup
} from 'devextreme-react/data-grid';
import { Template } from 'devextreme-react/core/template';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import apiUrls from '../../common/api/api-urls';
import { trans } from '../../common/utils';
import { languages } from '../../configs/i18n';
import {
    LanguageTemplate,
    dataGridDefaultSettings,
} from '../../components';

const LogEmailMessagePage = ({
    store: {
        languages: { table },
    },
}) => {
    const ref = useRef();

    return (
        <>
            <DataGrid
                ref={ref}
                {...dataGridDefaultSettings({
                    settingStorageName: 'BMA_grid_logs_emailMessages_USERID',
                    url: apiUrls.logs.emailMessages,
                    key: 'id',
                    language: languages.languageId[table],
                    languagePicker: false,
                    toolbarCenter: `${useLocation().pathname}`,
                    editing: {
                        allowAdding: false,
                        allowUpdating: false,
                        allowDeleting: false,
                    },
                })}
            >
                <HeaderFilter visible={true} allowSearch={true} />

                <Column
                    dataField={'id'}
                    caption={trans('ID')}
                    dataType={'number'}
                    width={90}
                    sortOrder={'desc'}
                    allowFixing={false}
                    allowHiding={false}
                    showInColumnChooser={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'subject'}
                    caption={trans('Тема')}
                    dataType={'string'}
                    width={200}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                />
                <Column
                    dataField={'email'}
                    caption={trans('Email')}
                    dataType={'string'}
                    allowFixing={false}
                    width={278}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'message'}
                    caption={trans('Сообщение')}
                    dataType={'string'}
                    width={300}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'isHtml'}
                    caption={trans('Html?')}
                    dataType={'boolean'}
                    width={60}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'isSent'}
                    caption={trans('Оправлено?')}
                    dataType={'boolean'}
                    width={60}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField={'isError'}
                    caption={trans('Ошибка?')}
                    dataType={'boolean'}
                    width={60}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'errorMessage'}
                    caption={trans('Текст ошибки')}
                    dataType={'string'}
                    width={150}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'sentDt'}
                    caption={trans('Дата отправки')}
                    dataType={'datetime'}
                    format={'dd/MM/yyyy HH:mm:ss'}
                    width={180}
                    allowFixing={false}
                    allowSearch={false}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    dataField={'idt'}
                    caption={trans('Дата вставки')}
                    dataType={'datetime'}
                    format={'dd/MM/yyyy HH:mm:ss'}
                    width={180}
                    visible={false}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                />

                <Column
                    caption={''}
                    width={50}
                    visible={true}
                    allowFixing={false}
                    allowEditing={false}
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    allowHiding={false}
                    allowReordering={false}
                />

                <Template
                    name={'languageBar'}
                    render={() => <LanguageTemplate />}
                />

            </DataGrid>
        </>

    );
};

export default inject('store')(observer(LogEmailMessagePage));
