import {types} from 'mobx-state-tree';


const ActivePageForSelect = types
    .model('ActivePageForSelect', {
        pageId: types.maybeNull(types.string)
    })
    .actions((self) => {
        return {
            setActivePage(name) {
                self.pageId = name;
            }
        };
    });

export default ActivePageForSelect;