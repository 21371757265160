import Base from '../base';
import apiUrls from '../api-urls';

export default class NewAdminAPI extends Base {
    async addNewAdmin(data) {
        return this.apiClient.post(apiUrls.newAdmin.addNewAdmin,data,true);
    }
    async addNewOfficeAdmin(data) {
        return this.apiClient.post(apiUrls.newAdmin.addNewOfficeAdmin,data,true);
    }

}
