import { API_PREFIX } from "./api-url-config";

const prefix = API_PREFIX + "/api";

const apiUrls = {
    info: {
        incomePercent: prefix + "/incomePercent",
        payer: prefix + "/payer",
        service: prefix + "/service",
        providerService: prefix + "/providerService",
        pay2playPinCode: prefix + "/pay2playPincode",
    },

    logs: {
        currency: prefix + "/logs/currency",
        pinCode: prefix + "/logs/pinCode",
        incomePercent: prefix + "/logs/incomePercent",
        payer: prefix + "/logs/payer",
        service: prefix + "/logs/service",
        serviceProviderDataExchange: prefix + "/logs/serviceProviderDataExchange",
        transactionServiceProviderStatus: prefix + "/logs/trans_serviceProviderStatus",
        verifyQuery: prefix + "/logs/verifyQuery",
        emailMessages: prefix + "/notification/email",

    },

    menu: {
        list: prefix + "/menu/list",
        edit: prefix + "/menu",
        tree: prefix + "/menu/tree",
        operation: prefix + "/menu/operation",
        sub: prefix + "/menu/sub",
    },

    settings: {
        currency: prefix + "/currency",
        emailMessageTemplate: prefix + "/emailMessageTemplate",
    },

    transaction: {
        paymentProvider: prefix + "/transaction/paymentProvider",
        serviceProvider: prefix + "/transaction/serviceProvider",
    },

    user: {
        check: prefix + "/account/check",
        login: prefix + "/account/login",
        logout: prefix + "/account/logout",
        resetSettings: prefix + "/account/removeStateStoring",
        changePassword: prefix + "/account/changePassword",
    },

    stateStoring: {
        save: prefix + "/statestore",
        load: prefix + "/statestore",
    },

    lookup: {
        menuOperation: prefix + "/lookup/menuOperation",
        menuSubMenu: prefix + "/lookup/menuSubmenu",
        menuMain: prefix + "/lookup/menuMain",
        user: prefix + "/lookup/users",
        service: prefix + "/lookup/service",
        parentServices: prefix + "/lookup/parentServices",
        serviceProvider: prefix + "/lookup/serviceProvider",
        paymentProvider: prefix + "/lookup/paymentProvider",
        currency: prefix + "/lookup/currency",
        customer: prefix + "/lookup/customer",
    },

    fileManager: {
        cdn: prefix + "/filemanager?rootDirectory=cdn"
    }
};

export default apiUrls;
