import { createStore } from 'devextreme-aspnet-data-nojquery';

import api from '../common/api';
import { trans } from '../common/utils';

let rowCount = 0;

const previousStates = {};

const dataGridDefaultSettings = ({
    /* The column chooser allows a user to hide columns at runtime. */
    columnChooser = { enabled: true, mode: 'dragAndDrop' },
    /* When the width of all columns exceeds the widget width, horizontal scrolling appears.
    If specific columns should be on screen constantly regardless of how far the widget is scrolled,
    allow a user to fix them at runtime using the context menu. */
    columnFixing = { enabled: true },
    /* The columnResizingMode option accepts one of the following values:
    nextColumn - When a user resizes a column, the width of the next column changes.
    widget - When a user resizes a column, the width of the widget changes. */
    columnResizingMode = 'widget',
    /* For some customization of DataSource */
    customFilter = {},
    /* Configures editing.
    check this for full description - https://js.devexpress.com/Documentation/Guide/Widgets/DataGrid/Editing/ */
    editing = {
        mode: 'batch',
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: false,
        startEditAction: 'dblClick',
        selectTextOnEditStart: true,
    },
    /* Array of field names that will be editable on create a new item, but will not be editable on update */
    editorPreparingArray = [],
    /* Configures filters preset select */
    presetFilters,
    /* Configures the filter row. */
    filterRow = { visible: true, applyFilter: 'auto' },
    /* Configures the group panel. */
    groupPanel = { visible: false },
    /* Configures grouping.
    check this for full description - https://js.devexpress.com/Documentation/Guide/Widgets/DataGrid/Grouping/ */
    grouping = {
        autoExpandAll: false, //Set the grouping.autoExpandAll option to false to collapse all the groups at startup. /
        remoteOperations: true, //To notify the DataGrid that it works with a pre-processed dataset, set the remoteOperations option to true.
        contextMenuEnabled: true, //Set this option to true to enable grouping using the context menu. the user right-clicks a column header and chooses the required item from the appeared context menu.
        expandMode: 'rowClick',
    },
    /* What is key (Unique) in DB */
    key,
    /* Will be send with requests to backend for language based data */
    language,
    /* will be used in table dataSource loadParams value */
    loadParams = null,
    /* Configures the load panel. */
    loadPanel = { enabled: false },
    /* Callback for data is loaded */
    onDataLoaded = () => { },
    /* A function that is executed when the widget's content is ready and each time the content is changed. Can be null */
    onContentReady,
    /* A function that executed when the focused row changes. Applies only to data rows. focusedRowEnabled should be true. Can be null */
    onFocusedRowChanged,
    /* For page size (with infinite scroll - for 'take' and 'skip' values */
    paging = {
        pageSize: 20,
    },
    /* Scroll behaviour - can be Standard, Infinite or Virtual*/
    scrolling = {
        mode:
            localStorage.getItem('scrollType') === 'virtual'
                ? 'virtual'
                : 'infinite', // - will load only paging.pageSize (like 'load more')
        preloadEnabled: false,
        // mode: 'virtual' // - will build all rows then will fill them upon scroll down
    },
    /* Configures the search panel. */
    searchPanel = {
        visible: true,
        highlightCaseSensitive: true,
    },
    /* Custom param to identify DB record value to store table settings for a user. String. Can be null to turn off save state */
    settingStorageName,
    /* Multiple Record Selection */
    selection = { mode: 'single' },
    /* Configures state storing. */
    stateStoring = {
        enabled: true,
    },
    /* Styles will be applied to table */
    style = {
        height: `calc(100vh - ${window.__appStore.theme.themeName() === 'dark' ? '74' : '54'
            }px)`,
        width: '99.5vw',
    },
    /* URL to get data from or post data to. Will be used in dataSource: loadUrl, insertUrl, updateUrl, deleteUrl */
    url,
    /* Custom prop to default filtering columns */
    defaultFilteringItems = [],

    /* TOOLBAR OPTIONS */
    /* Custom prop to hide/show language picker */
    languagePicker = true,
    toolbar = languagePicker
        ? { template: 'languageBar', location: 'before' }
        : {},
    /* Custom prop to show grid name */
    toolbarCenter = null,
    /* Custom prop to show additional switches or buttons */
    toolbarAfter = null,
    /* Custom prop to show limits input (only for customer sports limits) */
    customerEmailSender = null,
    /* Custom prop to show messages menu (only for customer email tab) */
    toolbarLimit = null,
    /* Custom prop to hide/show row count */
    withRowsCount = true,
    /* min text length to apply search in search panel */
    searchPanelMinLength = 1,
    /* event to interact with context menu */
    onContextMenuPreparing,
    onToolbarPreparing,
}) => {
    let gridRef, activeIndex;
    const dataSource = {
        ...customFilter,
        store: createStore({
            key: key,
            loadUrl: url,
            insertUrl: url,
            updateUrl: url,
            deleteUrl: url,
            loadParams: {
                ...loadParams,
                requireTotalCount: withRowsCount,
            },
            onLoaded: (e) => {
                window.__store.loader.setLoader(false);
                onDataLoaded(e);
            },
            onAjaxError: (e) => {
                window.__store.loader.setLoader(false);
                console.log('Error loading data' + e.xhr.statusText);
                if (e.xhr.status === 401) {
                    window.__appStore.user.setNeedRelogin(true);
                }
            },
            onBeforeSend: (method, ajaxOptions) => {
                window.__store.loader.setLoader(true);
                ajaxOptions.data.languageid = language;

                if (!ajaxOptions.data.take && !ajaxOptions.data.skip) {
                    ajaxOptions.data.skip = 0;
                    ajaxOptions.data.take = 100000;
                }
                ajaxOptions.xhrFields = { withCredentials: true };
            },
        }),
    };

    const resetSetings = () => {
        gridRef && gridRef.component.state({});
    };

    const convertingDatesStateFilters = (state) => {
        //needed for header filter (dateRangeFilter)
        state.columns &&
            state.columns.map((column) => {
                if (column.dataType === 'date') {
                    column.filterValues &&
                        column.filterValues.map((value) => {
                            if (Array.isArray(value)) {
                                value.map((item) => {
                                    if (Array.isArray(item)) {
                                        item[2] = new Date(item[2]);
                                    }
                                });
                            }
                        });
                }
            });
        return state;
    };

    const clearStateFilters = (state, needSetDefault) => {
        state.columns &&
            state.columns.map((column) => {
                if (column.filterValue || column.filterValue === null)
                    delete column.filterValue;
                if (column.filterValues || column.filterValues === null)
                    delete column.filterValues;
                if (
                    column.selectedFilterOperation ||
                    column.selectedFilterOperation === null
                )
                    delete column.selectedFilterOperation;
            });
        state.focusedRowKey && delete state.focusedRowKey;
        state.selectedRowKeys && delete state.selectedRowKeys;
        needSetDefault &&
            defaultFilteringItems.map((item) => {
                const column = state.columns.find(
                    (c) => c.dataField === item.dataField
                );
                if (Array.isArray(item.value)) {
                    if (column.filterType || column.filterType === null)
                        delete column.filterType;
                    column.filterValues = item.value;
                } else {
                    if (column.filterType || column.filterType === null)
                        delete column.filterType;
                    column.filterValue = item.value;
                    column.selectedFilterOperation = item.operation;
                }
            });
        return state;
    };

    const resetFiltersSetings = () => {
        if (gridRef) {
            const state = clearStateFilters(gridRef.component.state(), true);
            gridRef.component.state(state);
        }
    };

    const onToolbarPreparingEvent = (e) => {
        onToolbarPreparing && onToolbarPreparing(e);
        const resetBtn = {
            location: 'before',
            widget: 'dxButton',
            options: {
                stylingMode: 'outlined',
                elementAttr: {
                    class: `dx-icon dx-svg-icon reset-table-state-btn__${window.__appStore.theme.themeName()}`,
                    title: trans('Reset settings'),
                },
                icon:
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path d="M63 88C55.6224 80.6224 48.5375 72.0139 40 66C36.6728 92.3039 31.086 118.567 29 145C55.4575 144.781 81.5808 135.219 108 135C103.867 126.138 92.9478 116.599 85 111C101.316 90.9649 122.64 74.3761 145 61.5787C175.153 44.3213 209.401 34.4589 244 32.0895C321.022 26.8149 398.438 67.6853 441.255 131C463.751 164.266 475.395 201.359 479.17 241C481.769 268.292 478.159 296.953 469.485 323C459.966 351.584 445.499 378.074 425.869 401C403.39 427.253 374.816 448.474 343 462.127C271.021 493.016 185.635 482.574 123 435.525C91.8527 412.128 68.5015 381.979 51.7454 347C38.385 319.11 32 286.808 32 256L0 256C0 288.826 6.2086 321.552 18.6034 352C30.2365 380.577 47.0338 407.395 68.0895 430C150.678 518.666 288 537.698 391 473.575C418.656 456.357 442.383 434.027 461.873 408C483.863 378.635 498.691 344.758 506.576 309C512.741 281.036 514.466 250.289 509.753 222C502.452 178.177 487.414 137.798 460.525 102C393.282 12.4811 271.732 -23.0956 167 15.9499C138.911 26.4216 112.983 41.5064 90 60.7538C80.3527 68.8329 69.7355 77.2318 63 88z"/><path d="M160 128L160 256L128 256L128 288L160 288L160 384L192 384L192 288L224 288L224 256L192 256L192 128L160 128M240 128L240 176L208 176L208 208L240 208L240 384L272 384L272 208L307 208L307 176L272 176L272 128L240 128M320 128L320 304L288 304L288 336L320 336L320 384L352 384L352 336L384 336L384 304L352 304L352 128L320 128z"/></svg>',
                onClick: resetSetings,
            },
        };
        const resetFiltersBtn = {
            location: 'before',
            widget: 'dxButton',
            options: {
                stylingMode: 'outlined',
                elementAttr: {
                    class: `dx-icon dx-svg-icon reset-table-state-btn__${window.__appStore.theme.themeName()}`,
                    title: trans('Reset filters'),
                },
                icon:
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans" d="M 5 4 L 5 5 L 5 6 L 5 6.34375 L 5.21875 6.625 L 13 16.34375 L 13 26 L 13 28.03125 L 14.59375 26.78125 L 16.15625 25.59375 C 16.904325 29.233547 20.144371 32 24 32 C 28.406433 32 32 28.406433 32 24 C 32 19.593567 28.406433 16 24 16 C 22.111843 16 20.370873 16.680384 19 17.78125 L 19 16.34375 L 26.78125 6.625 L 27 6.34375 L 27 6 L 27 5 L 27 4 L 26 4 L 6 4 L 5 4 z M 7.28125 6 L 24.71875 6 L 17.53125 15 L 14.46875 15 L 7.28125 6 z M 15 17 L 17 17 L 17 20.125 C 16.48744 21.04728 16.143304 22.089812 16.03125 23.1875 L 15 24 L 15 17 z M 24 18 C 27.325553 18 30 20.674447 30 24 C 30 27.325553 27.325553 30 24 30 C 20.674447 30 18 27.325553 18 24 C 18 20.674447 20.674447 18 24 18 z M 21.71875 20.28125 L 20.28125 21.71875 L 22.5625 24 L 20.28125 26.28125 L 21.71875 27.71875 L 24 25.4375 L 26.28125 27.71875 L 27.71875 26.28125 L 25.4375 24 L 27.71875 21.71875 L 26.28125 20.28125 L 24 22.5625 L 21.71875 20.28125 z" overflow="visible" font-family="Bitstream Vera Sans"/></svg>',
                onClick: () => {
                    /* WTF is this why we repaint component before reset filters */
                    // gridRef.component.repaint();
                    resetFiltersSetings();
                },
            },
        };
        const sendCustomerMessageBtn = {
            location: 'before',
            widget: 'dxButton',
            options: {
                stylingMode: 'outlined',
                elementAttr: {
                    class: `dx-icon dx-svg-icon reset-table-state-btn__${window.__appStore.theme.themeName()}`,
                    title: trans('Send message'),
                },
                icon:
                    '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"\n' +
                    '\t viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">\n' +
                    '<g transform="translate(1 1)">\n' +
                    '\t<g>\n' +
                    '\t\t<path d="M479.685,60.644c-4.1-1.269-8.451-1.911-13.058-1.911H43.373c-4.608,0-8.959,0.642-13.059,1.911\n' +
                    '\t\t\tC12.065,66.141-1,82.917-1,103.107v303.787c0,12.278,4.835,23.291,12.725,31.276c0.277,0.412,0.58,0.802,0.928,1.15\n' +
                    '\t\t\tc2.215,1.993,4.605,3.751,7.12,5.27c6.256,3.889,13.578,6.264,21.484,6.624c0.705,0.034,1.41,0.052,2.115,0.052h423.253\n' +
                    '\t\t\tc0.705,0,1.411-0.018,2.115-0.052C492.482,450.133,511,430.93,511,406.893V103.107C511,82.917,497.934,66.141,479.685,60.644z\n' +
                    '\t\t\t M305.461,262.573L490.591,89.785c2.135,3.915,3.343,8.445,3.343,13.321v303.787c0,5.426-1.496,10.422-4.102,14.62\n' +
                    '\t\t\tL305.461,262.573z M466.627,75.8c0.502,0,0.99,0.014,1.469,0.038c0.239,0.012,0.474,0.034,0.711,0.052\n' +
                    '\t\t\tc0.257,0.019,0.517,0.035,0.769,0.062c0.123,0.013,0.243,0.031,0.365,0.045c2.81,0.339,5.297,1.122,7.779,2.364L289.769,254.107\n' +
                    '\t\t\tc-1.11,0.393-2.19,0.993-3.195,1.747l-6.827,5.973c-14.507,13.653-36.693,13.653-51.2,0l-0.923-0.807L32.28,78.36\n' +
                    '\t\t\tc2.482-1.241,4.969-2.025,7.779-2.364c0.122-0.014,0.242-0.032,0.365-0.045c0.252-0.027,0.512-0.042,0.77-0.062\n' +
                    '\t\t\tc0.237-0.018,0.472-0.04,0.711-0.052c0.479-0.024,0.967-0.038,1.469-0.038H466.627z M19.593,420.538\n' +
                    '\t\t\tc-2.247-3.989-3.526-8.633-3.526-13.645V103.107c0-4.876,1.208-9.406,3.343-13.321l184.31,172.023L19.593,420.538z M43.373,434.2\n' +
                    '\t\t\tc-2.768,0-5.536-0.563-8.305-1.23c-0.601-0.184-1.19-0.394-1.771-0.617l183.681-158.169l0.476,0.444\n' +
                    '\t\t\tc3.516,2.975,7.204,5.518,11.036,7.608c20.16,11.555,46.703,9.023,64.058-7.608l0.885-0.826l183.272,158.551\n' +
                    '\t\t\tc-0.582,0.223-1.17,0.433-1.772,0.618c-2.768,0.667-5.536,1.23-8.305,1.23H43.373z"/>\n' +
                    '\t</g>\n' +
                    '</g>\n' +
                    '</svg>',
                onClick: () => {
                    gridRef.component.repaint();
                    customerEmailSender(true);
                },
            },
        };

        if (toolbar || toolbarCenter || toolbarAfter) {
            if (toolbar && !toolbarLimit) {
                e.toolbarOptions.items.unshift(resetBtn);
                filterRow &&
                    filterRow.visible &&
                    e.toolbarOptions.items.unshift(resetFiltersBtn);
                e.toolbarOptions.items.unshift({
                    location: toolbar ? toolbar.location : null,
                    template: toolbar ? toolbar.template : null,
                });
            } else if (toolbarLimit) {
                e.toolbarOptions.items.unshift(toolbarLimit);
            }
            if (!toolbar && stateStoring.enabled) {
                customerEmailSender &&
                    e.toolbarOptions.items.unshift(sendCustomerMessageBtn);
                e.toolbarOptions.items.unshift(resetBtn);
                filterRow &&
                    filterRow.visible &&
                    e.toolbarOptions.items.unshift(resetFiltersBtn);
            }
            if (toolbarCenter) {
                if (toolbarCenter.template) {
                    e.toolbarOptions.items.unshift({
                        location: toolbarCenter.location,
                        template: toolbarCenter.template,
                    });
                } else {
                    e.toolbarOptions.items.unshift({
                        location: 'center',
                        template: `<div class="page-header-name">
                                    ${trans(toolbarCenter)}
                                </div>`,
                    });
                }
            }
            if (toolbarAfter) {
                e.toolbarOptions.items.unshift({
                    location: toolbarAfter ? toolbarAfter.location : null,
                    template: toolbarAfter ? toolbarAfter.template : null,
                });
            }
            e.toolbarOptions.items.unshift({
                location: withRowsCount ? 'before' : null,
                template: withRowsCount
                    ? `
                        <div class="template-custom-header-component" >
                        <p>${trans('Строк: ')}<span id="row-count">${rowCount}</span></p>
                        </div>`
                    : null,
            });
            if (presetFilters) {
                const selectPreset = {
                    location: 'after',
                    widget: 'dxSelectBox',
                    options: {
                        displayExpr: 'text',
                        valueExpr: 'value',
                        placeholder: trans('Filter'),
                        elementAttr: {
                            id: 'preset-filter_picker',
                        },
                        wrapItemText: true,
                        value: presetFilters.value,
                        items: presetFilters.items.map((item, i) => ({
                            value: item.filter,
                            text: item.name,
                        })),
                        onValueChanged: (e) => {
                            if (e.value) {
                                gridRef.component.columnOption(
                                    'scheduled',
                                    'sortOrder',
                                    'asc'
                                );
                                gridRef.component.clearFilter();
                                gridRef.component.filter(e.value);
                                presetFilters.setter(e.value.value);
                            }
                        },
                    },
                };
                e.toolbarOptions.items.unshift(selectPreset);
            }
        } else {
            e.toolbarOptions.visible = false;
        }
        if (toolbar === false) {
            e.toolbarOptions.visible = false;
        }
    };

    const onEditorPreparing = (e) => {
        if (
            editorPreparingArray.includes(e.dataField) &&
            e.parentType === 'dataRow'
        ) {
            if (!e.row.isNewRow) {
                e.validationRules = [];
            }
            e.editorOptions.disabled = !e.row.isNewRow;
        }

        // length of text to apply search in search panel
        if (e.parentType === 'searchPanel') {
            e.editorOptions.placeholder = `Search (min:${searchPanelMinLength})`;
            e.editorOptions.onValueChanged = function (editor) {
                if (
                    editor.value.length == 0 ||
                    editor.value.length > searchPanelMinLength - 1
                ) {
                    e.component.searchByText(editor.value);
                }
            };
        }
    };

    const onRowValidating = (e) => {
        if (
            typeof e.oldData === 'object' &&
            e.brokenRules.length > 0 &&
            (!e.newData[key] || e.oldData[key] === e.newData[key])
        ) {
            let hasOtherErrors = false;
            e.brokenRules.map((brokenRule) => {
                if (
                    brokenRule.type !== 'range' ||
                    brokenRule.column.dataField !== key
                ) {
                    hasOtherErrors = true;
                }
            });
            if (!hasOtherErrors) {
                e.isValid = true;
            }
        }
    };

    const onCellPrepared = (e) => {
        if (e.rowType == "header") {
            e.cellElement.style.textAlign = "center";
        }
    };

    const saveState = (state) => {
        //we don't store selections, focuses  on rows and page index. These props were removed to make less server calls.
        delete state.focusedRowKey;
        delete state.selectedRowKeys;
        delete state.pageIndex;

        if (
            settingStorageName &&
            JSON.stringify(state) !==
            JSON.stringify(previousStates[settingStorageName])
        ) {
            const prevURL = localStorage.getItem('prevURL');
            api.stateStoring.save(
                settingStorageName,
                prevURL === document.location.pathname
                    ? state
                    : clearStateFilters(state, false)
            );
            previousStates[settingStorageName] = state;
        }
    };

    const loadState = async () => {
        if (settingStorageName) {
            const r = await api.stateStoring.load(settingStorageName);
            if (r && r !== '' && JSON.parse(r)) {
                const prevURL = localStorage.getItem('prevURL');
                return prevURL === document.location.pathname
                    ? convertingDatesStateFilters(JSON.parse(r))
                    : clearStateFilters(JSON.parse(r), true);
            }
        }
        return null; // (на подумать позже) после сброса настроек и первого лоадинга пустого грид стейта, если не обновить страницу, грид не сохраняет значения выбраных фильтров
    };

    // let infiniteCount;
    // dataSource.totalCount().then(rowCount => {
    //     infiniteCount= rowCount
    // });

    const setTotalCounter = (instance) => {
        if (instance.rowIndex >= 0) {
            activeIndex = instance.rowIndex;
        }
        const buildCounter = (counter) => {
            return activeIndex >= 0
                ? `${activeIndex + 1} of ${counter}`
                : counter < 0
                    ? 0
                    : counter;
        };
        if (withRowsCount) {
            // if (scrolling.mode === 'infinite') {
            //     document.getElementById(
            //         'row-count'
            //     ).innerText = buildCounter(infiniteCount);

            // } else {
            let totalCount = instance.component.totalCount();
            document.getElementById('row-count').innerText = buildCounter(
                totalCount
            );
            // }
        }
    };

    // 👇 Add "hide column btn" to header context menu
    const addHideColumnBtn = (e) => {
        if ((e.target = 'header' && e.items)) {
            if (e.column.allowHiding) {
                e.items.push({
                    text: 'Hide column',
                    value: 'none',
                    onItemClick: () => {
                        e.component.columnOption(
                            e.column.caption,
                            'visible',
                            false
                        );
                    },
                });
            }
        }
    };

    return {
        allowColumnReordering: true,
        allowColumnResizing: true,
        columnAutoWidth: true,
        columnChooser: columnChooser,
        columnFixing: columnFixing,
        columnMinWidth: 50,
        columnResizingMode: columnResizingMode,
        dataSource: dataSource,
        editing: Object.assign(
            localStorage.getItem('scrollType') === 'virtual'
                ? {
                    mode: 'batch',
                    allowAdding: true,
                    allowUpdating: true,
                    allowDeleting: false,
                    startEditAction: 'dblClick',
                    selectTextOnEditStart: true,
                }
                : {
                    mode: 'batch',
                    allowAdding: false,
                    allowUpdating: false,
                    allowDeleting: false,
                    selectTextOnEditStart: true,
                    startEditAction: 'dblClick',
                },
            localStorage.getItem('scrollType') === 'virtual' && editing
        ),
        //DataGrid makes it easy to export your data to an Excel file. Button top-right.
        export: { enabled: true, allowExportSelectedData: true },
        filterRow: filterRow,
        focusedRowEnabled: true,
        groupPanel: groupPanel,
        grouping: grouping,
        loadPanel: loadPanel,
        onContentReady: (instance) => {
            onContentReady && onContentReady(instance);
            gridRef = instance;
            setTotalCounter(instance);
        },
        onEditorPreparing: onEditorPreparing,
        onFocusedRowChanged: (instance) => {
            setTotalCounter(instance);
            onFocusedRowChanged && onFocusedRowChanged(instance);
        },
        onContextMenuPreparing: (e) => {
            onContextMenuPreparing && onContextMenuPreparing(e);
            addHideColumnBtn(e);
        },
        onRowValidating: onRowValidating,
        onCellPrepared: onCellPrepared,
        onToolbarPreparing: onToolbarPreparingEvent,
        paging: paging,
        remoteOperations: true,
        scrolling: scrolling,
        searchPanel: searchPanel,
        selection: selection,
        showBorders: true,
        showColumnLines: true,
        sorting: { mode: 'multiple' },
        stateStoring: {
            ...stateStoring,
            type: 'custom',
            customLoad: loadState,
            customSave: saveState,
        },
        style: style,
        legacyRendering: true, //if rowRenderingMode virtual wil be turn on
    };
};

export default dataGridDefaultSettings;
